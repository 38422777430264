import React, { useState, useEffect } from 'react';
import './Dashboard.css';

function Dashboard() {
    const [darkMode, setDarkMode] = useState(false);
    const [userName, setUserName] = useState('');
    const [workDuration, setWorkDuration] = useState(25); // Default 25 minutes
    const [breakDuration, setBreakDuration] = useState(5); // Default 5 minutes
    const [isTimerActive, setIsTimerActive] = useState(false);
    const [timeLeft, setTimeLeft] = useState(workDuration * 60); // Time in seconds
    const [isWorking, setIsWorking] = useState(true); // true for work timer, false for break timer

    useEffect(() => {
        // Fetch user data from local storage or state management on component mount
        const user = JSON.parse(localStorage.getItem('user'));
        if (user && user.name) {
            setUserName(user.name);
        }
    }, []);

    useEffect(() => {
        // Store userName in localStorage when it changes
        localStorage.setItem('user', JSON.stringify({ name: userName }));
    }, [userName]);

    useEffect(() => {
        let interval = null;
        if (isTimerActive) {
            interval = setInterval(() => {
                setTimeLeft(prevTime => {
                    if (prevTime <= 0) {
                        if (isWorking) {
                            setIsWorking(false); // Switch to break timer
                            return breakDuration * 60;
                        } else {
                            setIsWorking(true); // Switch to work timer
                            return workDuration * 60;
                        }
                    }
                    return prevTime - 1;
                });
            }, 1000);
        } else if (!isTimerActive && timeLeft !== (isWorking ? workDuration * 60 : breakDuration * 60)) {
            clearInterval(interval);
        }
        return () => clearInterval(interval);
    }, [isTimerActive, timeLeft, isWorking, workDuration, breakDuration]);

    const toggleDarkMode = () => {
        setDarkMode(!darkMode);
    };

    const handleStartStop = () => {
        setIsTimerActive(!isTimerActive);
    };

    const handleReset = () => {
        setIsTimerActive(false);
        setTimeLeft(isWorking ? workDuration * 60 : breakDuration * 60);
    };

    const formatTime = (seconds) => {
        const minutes = Math.floor(seconds / 60);
        const secs = seconds % 60;
        return `${String(minutes).padStart(2, '0')}:${String(secs).padStart(2, '0')}`;
    };

    return (
        <div className={`dashboard-container ${darkMode ? 'dark' : 'light'}`}>
            <aside className="sidebar">
                <nav className="flex-1">
                    <ul>
                        <li><a href="/notes" className="sidebar-link">Notes</a></li>
                        <li><a href="/schedule" className="sidebar-link">Schedule</a></li>
                        <li><a href="/garden" className="sidebar-link">Garden</a></li>
                        <li><a href="/tasks" className="sidebar-link">Tasks</a></li>
                        <li><a href="/reminders" className="sidebar-link">Reminders</a></li>
                        <li><a href="/timer" className="sidebar-link">Timers</a></li>
                        <li><a href="/pomodoro" className="current-sidebar-link">Pomodoro</a></li>
                    </ul>
                </nav>
                <div className="sidebar-footer">
                    <button>{userName}</button>
                </div>
            </aside>
            <div className="main-content">
                <header className="navbar">
                    <h1>MindBloom | Pomodoro</h1>
                    <nav>
                        <ul>
                            <li><a href="/" className="navbar-link">Home</a></li>
                            <li><a href="/about" className="navbar-link">About</a></li>
                            <li><a href="/contact" className="navbar-link">Contact</a></li>
                        </ul>
                    </nav>
                </header>
                <div className="content">
                    <h2>Pomodoro Timer</h2>
                    <div className="timer-settings">
                        <label>
                            Work Duration (minutes):
                            <input 
                                type="number" 
                                value={workDuration} 
                                onChange={(e) => setWorkDuration(Number(e.target.value))} 
                            />
                        </label>
                        <label>
                            Break Duration (minutes):
                            <input 
                                type="number" 
                                value={breakDuration} 
                                onChange={(e) => setBreakDuration(Number(e.target.value))} 
                            />
                        </label>
                    </div>
                    <div className="timer-display">
                        <h3>{isWorking ? 'Work Timer' : 'Break Timer'}</h3>
                        <p>{formatTime(timeLeft)}</p>
                    </div>
                    <div className="timer-controls">
                        <button onClick={handleStartStop}>
                            {isTimerActive ? 'Pause' : 'Start'}
                        </button>
                        <button onClick={handleReset}>Reset</button>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Dashboard;
