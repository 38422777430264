import './Blog.css';
import '../../App.css';
import React from 'react';
import { Link } from 'react-router-dom';
import AOS from 'aos';
import 'aos/dist/aos.css';
import { BiLogoInstagram, BiLogoFacebook, BiLogoTiktok, BiLogoDiscordAlt } from 'react-icons/bi';

const BlogPage = () => {
  return (
    <div className='blog-app'>
      <div className="home-nav">
        <img src="logo192.png" alt="MindBloom Logo" />
        <h1>MindBloom</h1>
        <div className='home-index-buttons'>
          <Link className="home-index-links" to="/">About</Link>
          <Link className="home-index-links" to="/pricing">Pricing</Link>
          <Link className="home-index-links" to="/contact">Contact</Link>
          <Link className="home-index-links" to="/blogs">Blog</Link>
        </div>
        <div className="home-auth-buttons">
          <Link className="home-login-link" to="/login">Login</Link>
          <Link className="home-signup-link" to="/signup">Signup</Link>
        </div>
      </div>

      <div className="blog-container">
        <h1 className="blog-title">OCD: Understanding Symptoms and Finding Effective Coping Strategies</h1>
        
        <div className="blog-content">
          <h2>What is OCD?</h2>
          <p>Obsessive-Compulsive Disorder (OCD) is a mental health condition characterized by persistent, unwanted thoughts (obsessions) and repetitive behaviors or mental acts (compulsions) performed to alleviate the distress caused by these thoughts. Understanding OCD and finding effective coping strategies is crucial for managing the condition and improving quality of life.</p>
          
          <h2>Common Symptoms</h2>
          <p>OCD symptoms can vary widely, but some common themes include:</p>
          <ul>
            <li><strong>Contamination:</strong> Fear of germs or dirt leading to excessive cleaning.</li>
            <li><strong>Checking:</strong> Repeatedly verifying that doors are locked or appliances are turned off.</li>
            <li><strong>Symmetry:</strong> A need for things to be arranged in a particular order or symmetry.</li>
            <li><strong>Hoarding:</strong> Difficulty discarding items, leading to clutter and potential distress.</li>
          </ul>
          
          <h2>Finding Effective Coping Strategies</h2>
          <p>Managing OCD involves a combination of therapeutic approaches and self-help strategies. Here are some effective ways to cope with OCD:</p>
          
          <h3>Cognitive-Behavioral Therapy (CBT):</h3>
          <p><strong>Exposure and Response Prevention (ERP):</strong> A form of CBT that helps individuals gradually face their fears and resist the urge to perform compulsive behaviors. ERP is one of the most effective treatments for OCD.</p>
          
          <h3>Medication:</h3>
          <p><strong>Selective Serotonin Reuptake Inhibitors (SSRIs):</strong> These medications can help reduce OCD symptoms by increasing serotonin levels in the brain. Consult with a healthcare provider to determine the best medication and dosage.</p>
          
          <h3>Mindfulness and Relaxation Techniques:</h3>
          <p><strong>Mindfulness Meditation:</strong> Practicing mindfulness can help individuals observe their thoughts without judgment and reduce the power of obsessions.</p>
          <p><strong>Deep Breathing Exercises:</strong> Techniques like deep breathing can help manage anxiety and stress related to OCD symptoms.</p>
          
          <h3>Support Groups:</h3>
          <p><strong>Connecting with Others:</strong> Joining support groups or online communities can provide emotional support, practical advice, and a sense of belonging.</p>
          
          <h2>Conclusion</h2>
          <p>Understanding OCD and implementing effective coping strategies is essential for managing the disorder and improving overall well-being. At MindBloom, we offer resources and tools to support individuals dealing with OCD. Seek professional help if you’re struggling, and explore our platform for additional support and guidance. Together, we can help you manage OCD and live a fulfilling life.</p>
          <p>If you’re ready to take the next step, explore our resources at MindBloom and connect with a professional today. Our platform is designed to help you stay organized, focused, and in control of your life. Start your journey with MindBloom and bloom to your full potential!</p>
        </div>
      </div>
      
      <footer className="footer">
        <div className="social-icons">
          <a href="https://instagram.com/mindbloomapp" target="_blank" rel="noopener noreferrer">
            <BiLogoInstagram />
          </a>
          <a href="https://facebook.com/mindbloomapp" target="_blank" rel="noopener noreferrer">
            <BiLogoFacebook />
          </a>
          <a href="https://tiktok.com/@mindbloomapp" target="_blank" rel="noopener noreferrer">
            <BiLogoTiktok />
          </a>
          <a href="https://discord.gg/73cSXYs86w" target="_blank" rel="noopener noreferrer">
            <BiLogoDiscordAlt />
          </a>
        </div>
        <div className="footer-links">
          <Link to="/terms">Terms of Service</Link>
          <Link to="/privacy">Privacy Policy</Link>
          <Link to="/legal">Legal</Link>
        </div>
        <p>&copy; 2024 MindBloom. All rights reserved.</p>
      </footer>
    </div>
  );
};

export default BlogPage;
