import React, { useState } from 'react';
import { loadStripe } from '@stripe/stripe-js';
import { Elements, CardElement, useStripe, useElements } from '@stripe/react-stripe-js';
import axios from 'axios';
import '../App.css';

// Replace with your actual Stripe public key
const stripePromise = loadStripe('pk_live_51PdOqDKeHvWeWQkV8oB6jfd8iOxkM7MO56UlBX67SOyImC442HR53py7eqxbBfxBUOxwNGtexGQ0iNVSpQUgfXWQ009BWdRMZC');

const CheckoutForm = () => {
  const stripe = useStripe();
  const elements = useElements();
  const [amount, setAmount] = useState(0);
  const [error, setError] = useState(null);
  const [success, setSuccess] = useState(false);

  const handleSubmit = async (event) => {
    event.preventDefault();

    if (!stripe || !elements) {
      return;
    }

    const { error, paymentIntent } = await stripe.createPaymentIntent({
      amount: amount * 100, // Amount in cents
      currency: 'usd',
    });

    if (error) {
      setError(error.message);
      return;
    }

    const cardElement = elements.getElement(CardElement);

    const { error: stripeError, paymentMethod } = await stripe.createPaymentMethod({
      type: 'card',
      card: cardElement,
    });

    if (stripeError) {
      setError(stripeError.message);
      return;
    }

    const { error: confirmError } = await stripe.confirmCardPayment(paymentIntent.client_secret, {
      payment_method: paymentMethod.id,
    });

    if (confirmError) {
      setError(confirmError.message);
      return;
    }

    setSuccess(true);
  };

  return (
    <form onSubmit={handleSubmit}>
      <label>
        Donation Amount:
        <input
          type="number"
          value={amount}
          onChange={(e) => setAmount(e.target.value)}
        />
      </label>
      <CardElement />
      <button type="submit" disabled={!stripe}>
        Donate
      </button>
      {error && <div>{error}</div>}
      {success && <div>Thank you for your donation!</div>}
    </form>
  );
};

const Donate = () => (
  <Elements stripe={stripePromise}>
    <CheckoutForm />
  </Elements>
);

export default Donate;
