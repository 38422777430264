import React, { useState, useEffect } from 'react';
import { Client, Databases, Account, Query } from 'appwrite';
import './Dashboard.css';

function Dashboard() {
  const [darkMode, setDarkMode] = useState(false);
  const [notes, setNotes] = useState([]);
  const [showAddPopup, setShowAddPopup] = useState(false);
  const [showEditPopup, setShowEditPopup] = useState(false);
  const [popupContent, setPopupContent] = useState({ title: '', description: '' });
  const [userName, setUserName] = useState('');
  const [userId, setUserId] = useState('');

  const client = new Client();
  client
    .setEndpoint('https://cloud.appwrite.io/v1')
    .setProject('668c60c700398dc80f00');
  const databases = new Databases(client);
  const account = new Account(client);

  useEffect(() => {
    const getUserData = async () => {
      try {
        const user = await account.get();
        setUserName(user.name);
        setUserId(user.$id);
        localStorage.setItem('user', JSON.stringify({ id: user.$id, name: user.name }));

        fetchNotes(user.$id);
      } catch (error) {
        console.error('Error fetching user data:', error.message);
      }
    };

    getUserData();
  }, []);

  const fetchNotes = async (userId) => {
    try {
      const response = await databases.listDocuments(
        '6690622800253f723a68', // Your database ID
        '669062430000afd69248', // Your collection ID
        [Query.equal('userId', userId)] // Filter notes by user ID
      );
      setNotes(response.documents);
    } catch (error) {
      console.error('Error fetching notes from Appwrite:', error.message);
    }
  };

  const toggleDarkMode = () => {
    setDarkMode(!darkMode);
  };

  const openAddPopup = () => {
    setPopupContent({ title: '', description: '' });
    setShowAddPopup(true);
  };

  const closeAddPopup = () => {
    setShowAddPopup(false);
  };

  const openEditPopup = (note) => {
    setPopupContent({ id: note.$id, title: note.title, description: note.description });
    setShowEditPopup(true);
  };

  const closeEditPopup = () => {
    setShowEditPopup(false);
  };

  const handlePopupChange = (e) => {
    const { name, value } = e.target;
    setPopupContent((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  const handleAddNote = async () => {
    const user = JSON.parse(localStorage.getItem('user'));
    const newNote = {
      title: popupContent.title,
      description: popupContent.description,
      userId: user.id,
    };

    try {
      const response = await databases.createDocument(
        '6690622800253f723a68',
        '669062430000afd69248',
        'unique()',
        newNote
      );

      setNotes([...notes, response]);
      setShowAddPopup(false);
    } catch (error) {
      console.error('Error adding note to Appwrite:', error.message);
    }
  };

  const handleEditNote = async () => {
    const updatedNote = {
      title: popupContent.title,
      description: popupContent.description,
    };

    try {
      await databases.updateDocument(
        '6690622800253f723a68',
        '669062430000afd69248',
        popupContent.id,
        updatedNote
      );

      setNotes((prevNotes) =>
        prevNotes.map((note) =>
          note.$id === popupContent.id ? { ...note, title: updatedNote.title, description: updatedNote.description } : note
        )
      );
      setShowEditPopup(false);
    } catch (error) {
      console.error('Error editing note in Appwrite:', error.message);
    }
  };

  return (
    <div className={`dashboard-container ${darkMode ? 'dark' : 'light'}`}>
      <aside className="sidebar">
        <nav className="flex-1">
          <ul>
            <li><a href="/notes" className="current-sidebar-link">Notes</a></li>
            <li><a href="/schedule" className="sidebar-link">Schedule</a></li>
            <li><a href="/garden" className="sidebar-link">Garden</a></li>
            <li><a href="/tasks" className="sidebar-link">Tasks</a></li>
            <li><a href="/reminders" className="sidebar-link">Reminders</a></li>
            <li><a href="/timer" className="sidebar-link">Timers</a></li>
            <li><a href="/pomodoro" className="sidebar-link">Pomodoro</a></li>
          </ul>
        </nav>
        <div className="sidebar-footer">
          <button>{userName}</button>
        </div>
      </aside>
      <div className="main-content">
        <header className="navbar">
          <h1>MindBloom | Notes</h1>
          <nav>
            <ul>
              <li><a href="/" className="navbar-link">Home</a></li>
              <li><a href="/about" className="navbar-link">About</a></li>
              <li><a href="/contact" className="navbar-link">Contact</a></li>
            </ul>
          </nav>
        </header>
        <div className="content">
          <div className="note-list">
            {notes.map((note) => (
              <div className="note-container" key={note.$id}>
                <h3>{note.title}</h3>
                <p>{note.description}</p>
                <div className="note-buttons">
                  <button onClick={() => openEditPopup(note)}>Edit</button>
                  <button>Delete</button>
                </div>
              </div>
            ))}
          </div>
        </div>
        <button className="add-note-button" onClick={openAddPopup}>+</button>
      </div>

      {showAddPopup && (
        <div className="popup-overlay">
          <div className="popup">
            <div className="popup-content">
              <h2>Add Note</h2>
              <label>Title:</label>
              <input type="text" name="title" value={popupContent.title} onChange={handlePopupChange} />
              <label>Description:</label>
              <textarea name="description" value={popupContent.description} onChange={handlePopupChange} />
              <div className="popup-buttons">
                <button onClick={handleAddNote}>Add</button>
                <button onClick={closeAddPopup}>Cancel</button>
              </div>
            </div>
          </div>
        </div>
      )}

      {showEditPopup && (
        <div className="popup-overlay">
          <div className="popup">
            <div className="popup-content">
              <h2>Edit Note</h2>
              <label>Title:</label>
              <input type="text" name="title" value={popupContent.title} onChange={handlePopupChange} />
              <label>Description:</label>
              <textarea name="description" value={popupContent.description} onChange={handlePopupChange} />
              <div className="popup-buttons">
                <button onClick={handleEditNote}>Save Changes</button>
                <button onClick={closeEditPopup}>Cancel</button>
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
}

export default Dashboard;
