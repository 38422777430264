// Timer.js
import React, { useState, useEffect } from 'react';
import Countdown from 'react-countdown';
import { Client, Databases, Account } from 'appwrite';
import './Dashboard.css';

function Timer() {
    const [darkMode, setDarkMode] = useState(false);
    const [userName, setUserName] = useState('');
    const [userId, setUserId] = useState('');
    const [hours, setHours] = useState(0);
    const [minutes, setMinutes] = useState(0);
    const [seconds, setSeconds] = useState(0);
    const [isCountdownActive, setIsCountdownActive] = useState(false);
    const [countdownTime, setCountdownTime] = useState(0);
    const [pauseTime, setPauseTime] = useState(null);

    const client = new Client()
        .setEndpoint('https://cloud.appwrite.io/v1')
        .setProject('668c60c700398dc80f00');
    const databases = new Databases(client);
    const account = new Account(client);

    useEffect(() => {
        const getUserData = async () => {
            try {
                const user = await account.get(); // Fetch user data
                setUserName(user.name);
                setUserId(user.$id);
                localStorage.setItem('user', JSON.stringify({ id: user.$id, name: user.name }));
            } catch (error) {
                console.error('Error fetching user data:', error.message);
                // Handle specific error cases, e.g., redirect to login if not authenticated
            }
        };

        getUserData();
    }, []);

    const handleCountdownChange = (type, value) => {
        const numericValue = Math.max(0, parseInt(value, 10) || 0);
        switch (type) {
            case 'hours':
                setHours(numericValue);
                break;
            case 'minutes':
                setMinutes(numericValue);
                break;
            case 'seconds':
                setSeconds(numericValue);
                break;
            default:
                break;
        }
    };

    const handleStartCountdown = async () => {
        const totalSeconds = (hours * 3600) + (minutes * 60) + seconds;
        if (totalSeconds > 0) {
            setCountdownTime(totalSeconds);
            setIsCountdownActive(true);
            await saveTimer('start', totalSeconds); // Save timer start
        }
    };

    const handlePauseCountdown = async () => {
        setIsCountdownActive(false);
        setPauseTime(Date.now());
        await saveTimer('pause', countdownTime); // Save timer pause
    };

    const handleResumeCountdown = async () => {
        const elapsedTime = Math.floor((Date.now() - pauseTime) / 1000);
        setCountdownTime(countdownTime - elapsedTime);
        setIsCountdownActive(true);
        setPauseTime(null);
        await saveTimer('resume', countdownTime - elapsedTime); // Save timer resume
    };

    const handleResetCountdown = async () => {
        setIsCountdownActive(false);
        setHours(0);
        setMinutes(0);
        setSeconds(0);
        setCountdownTime(0);
        setPauseTime(null);
        await saveTimer('reset', 0); // Save timer reset
    };

    const saveTimer = async (status, timeRemaining) => {
        const timerData = {
            userId,
            hours,
            minutes,
            seconds,
            status,
            timeRemaining,
        };
    
        try {
            await databases.createDocument(
                '6692dc89001e991c0414', // Your collection ID
                'unique()', // Document ID - 'unique()' will auto-generate a unique ID
                timerData // Pass the timer data here
            );
        } catch (error) {
            console.error('Error saving timer to Appwrite:', error.message);
        }
    };

    const renderer = ({ hours, minutes, seconds, completed }) => {
        if (completed) {
            return <span>Time's up!</span>;
        } else {
            return (
                <span>{`${String(hours).padStart(2, '0')}:${String(minutes).padStart(2, '0')}:${String(seconds).padStart(2, '0')}`}</span>
            );
        }
    };

    return (
        <div className={`dashboard-container ${darkMode ? 'dark' : 'light'}`}>
            <aside className="sidebar">
                <nav className="flex-1">
                    <ul>
                        <li><a href="/notes" className="sidebar-link">Notes</a></li>
                        <li><a href="/schedule" className="sidebar-link">Schedule</a></li>
                        <li><a href="/garden" className="sidebar-link">Garden</a></li>
                        <li><a href="/tasks" className="sidebar-link">Tasks</a></li>
                        <li><a href="/reminders" className="sidebar-link">Reminders</a></li>
                        <li><a href="/timer" className="sidebar-link">Timers</a></li>
                        <li><a href="/pomodoro" className="sidebar-link">Pomodoro</a></li>
                    </ul>
                </nav>
                <div className="sidebar-footer">
                    <button>{userName}</button>
                </div>
            </aside>
            <div className="main-content">
                <header className="navbar">
                    <h1>MindBloom | Timer</h1>
                    <nav>
                        <ul>
                            <li><a href="/" className="navbar-link">Home</a></li>
                            <li><a href="/about" className="navbar-link">About</a></li>
                            <li><a href="/contact" className="navbar-link">Contact</a></li>
                        </ul>
                    </nav>
                </header>
                <div className="content">
                    <div className="countdown-display">
                        <h2>Countdown Timer</h2>
                        <div className="timer">
                            {isCountdownActive ? (
                                <Countdown
                                    date={Date.now() + countdownTime * 1000}
                                    renderer={renderer}
                                    onComplete={() => setIsCountdownActive(false)}
                                />
                            ) : (
                                <span>{`${String(hours).padStart(2, '0')}:${String(minutes).padStart(2, '0')}:${String(seconds).padStart(2, '0')}`}</span>
                            )}
                        </div>
                        <div className="timer-settings">
                            <div className="time-control">
                                <label>Hours:</label>
                                <input
                                    type="number"
                                    value={hours}
                                    onChange={(e) => handleCountdownChange('hours', e.target.value)}
                                    min="0"
                                    max="23"
                                />
                            </div>
                            <div className="time-control">
                                <label>Minutes:</label>
                                <input
                                    type="number"
                                    value={minutes}
                                    onChange={(e) => handleCountdownChange('minutes', e.target.value)}
                                    min="0"
                                    max="59"
                                />
                            </div>
                            <div className="time-control">
                                <label>Seconds:</label>
                                <input
                                    type="number"
                                    value={seconds}
                                    onChange={(e) => handleCountdownChange('seconds', e.target.value)}
                                    min="0"
                                    max="59"
                                />
                            </div>
                        </div>
                        <div className="timer-actions">
                            {!isCountdownActive && (
                                <button onClick={handleStartCountdown} disabled={(hours === 0 && minutes === 0 && seconds === 0)}>
                                    Start
                                </button>
                            )}
                            {isCountdownActive && (
                                <>
                                    <button onClick={handlePauseCountdown}>
                                        Pause
                                    </button>
                                    <button onClick={handleResetCountdown}>
                                        Reset
                                    </button>
                                </>
                            )}
                            {pauseTime && (
                                <button onClick={handleResumeCountdown}>
                                    Resume
                                </button>
                            )}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Timer;
