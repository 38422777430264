import React, { useEffect, useState } from 'react';
import { account, checkPremiumLabel } from './appwrite'; // Adjust the path as needed
import PremiumError from '../components/PremiumError'; // Adjust the path as needed

const withPremiumCheck = (WrappedComponent) => {
    return (props) => {
        const [isLoading, setIsLoading] = useState(true);
        const [hasPremiumAccess, setHasPremiumAccess] = useState(false);

        useEffect(() => {
            const checkAccess = async () => {
                try {
                    const isPremium = await checkPremiumLabel();
                    setHasPremiumAccess(isPremium);
                    setIsLoading(false);
                } catch (error) {
                    console.error('Access check error:', error);
                    setIsLoading(false);
                }
            };

            checkAccess();
        }, []);

        if (isLoading) {
            return <div>Loading...</div>;
        }

        if (!hasPremiumAccess) {
            return <PremiumError />;
        }

        return <WrappedComponent {...props} />;
    };
};

export default withPremiumCheck;
