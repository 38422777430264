import './Blog.css';
import '../../App.css';
import React from 'react';
import { Link } from 'react-router-dom';
import AOS from 'aos';
import 'aos/dist/aos.css';
import { BiLogoInstagram, BiLogoFacebook, BiLogoTiktok, BiLogoDiscordAlt } from 'react-icons/bi';

const BlogPage = () => {
  return (
    <div className='blog-app'>
      <div className="home-nav">
        <img src="logo192.png" alt="MindBloom Logo" />
        <h1>MindBloom</h1>
        <div className='home-index-buttons'>
          <Link className="home-index-links" to="/">About</Link>
          <Link className="home-index-links" to="/pricing">Pricing</Link>
          <Link className="home-index-links" to="/contact">Contact</Link>
          <Link className="home-index-links" to="/blogs">Blog</Link>
        </div>
        <div className="home-auth-buttons">
          <Link className="home-login-link" to="/login">Login</Link>
          <Link className="home-signup-link" to="/signup">Signup</Link>
        </div>
      </div>

      <div className="blog-container">
        <h1 className="blog-title">Navigating ADHD in the Workplace: Tips for Success</h1>
        
        <div className="blog-content">
          <h2>Introduction</h2>
          <p>Managing ADHD in a professional setting can be challenging, but with the right strategies, you can thrive in the workplace. This blog post provides tips and insights to help you navigate ADHD symptoms, improve productivity, and achieve career success.</p>
          
          <h2>Understanding Workplace Challenges</h2>
          <p>ADHD can impact various aspects of work, including task completion, time management, and interpersonal relationships. Recognizing these challenges is the first step toward finding effective solutions.</p>
          
          <h2>1. Prioritize Tasks and Set Goals</h2>
          <p>Prioritizing tasks and setting clear goals can help you stay focused and organized. Use tools like to-do lists, project management apps, and goal-setting frameworks to manage your workload effectively.</p>
          
          <h3>Create a Daily To-Do List:</h3>
          <p>List your tasks for the day, prioritize them, and check them off as you complete them. This can provide a sense of accomplishment and keep you on track.</p>
          
          <h3>Set SMART Goals:</h3>
          <p>Use the SMART criteria (Specific, Measurable, Achievable, Relevant, Time-bound) to set clear and attainable goals.</p>
          
          <h2>2. Optimize Your Workspace</h2>
          <p>An organized and distraction-free workspace can enhance your focus and productivity. Here are some tips to optimize your work environment:</p>
          
          <h3>Minimize Distractions:</h3>
          <p>Identify and eliminate potential distractions. Use noise-canceling headphones, close unnecessary tabs on your computer, and keep your workspace tidy.</p>
          
          <h3>Use Organizational Tools:</h3>
          <p>Utilize tools like calendars, planners, and file organizers to keep track of tasks and deadlines. Digital tools can also help you stay organized.</p>
          
          <h2>3. Manage Your Time Effectively</h2>
          <p>Time management is crucial for staying on top of your responsibilities. Here are some strategies to help you manage your time effectively:</p>
          
          <h3>Use a Timer:</h3>
          <p>Set a timer for specific work intervals (e.g., the Pomodoro Technique) to maintain focus and take regular breaks.</p>
          
          <h3>Break Tasks into Smaller Steps:</h3>
          <p>Large tasks can be overwhelming. Break them down into smaller, manageable steps and tackle them one at a time.</p>
          
          <h2>4. Communicate with Your Employer</h2>
          <p>Open communication with your employer about your ADHD can lead to better understanding and support. Here are some tips for discussing your needs:</p>
          
          <h3>Be Honest and Open:</h3>
          <p>Explain how ADHD affects your work and discuss potential accommodations that can help you perform better.</p>
          
          <h3>Suggest Reasonable Accommodations:</h3>
          <p>Accommodations might include flexible work hours, a quieter workspace, or additional time for tasks. Work with your employer to find solutions that work for both parties.</p>
          
          <h2>Conclusion</h2>
          <p>Thriving in the workplace with ADHD is possible with the right strategies and support. By prioritizing tasks, optimizing your workspace, managing your time effectively, and communicating with your employer, you can overcome challenges and achieve success in your career. At MindBloom, we're here to support you on this journey. Explore our resources and tools designed to help you stay organized and focused at work.</p>
        </div>
      </div>
      
      <footer className="footer">
        <div className="social-icons">
          <a href="https://instagram.com/mindbloomapp" target="_blank" rel="noopener noreferrer">
            <BiLogoInstagram />
          </a>
          <a href="https://facebook.com/mindbloomapp" target="_blank" rel="noopener noreferrer">
            <BiLogoFacebook />
          </a>
          <a href="https://tiktok.com/@mindbloomapp" target="_blank" rel="noopener noreferrer">
            <BiLogoTiktok />
          </a>
          <a href="https://discord.gg/73cSXYs86w" target="_blank" rel="noopener noreferrer">
            <BiLogoDiscordAlt />
          </a>
        </div>
        <div className="footer-links">
          <Link to="/terms">Terms of Service</Link>
          <Link to="/privacy">Privacy Policy</Link>
          <Link to="/legal">Legal</Link>
        </div>
        <p>&copy; 2024 MindBloom. All rights reserved.</p>
      </footer>
    </div>
  );
};

export default BlogPage;
