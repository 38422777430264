import React, { useState } from 'react';
import { account } from '../lib/appwrite';
import './Login.css';

const Login = () => {
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [error, setError] = useState('');

    const handleLogin = async (e) => {
        e.preventDefault();
        setError(''); // Clear previous errors
    
        try {
            // Clear local storage
            localStorage.clear();
    
            // Clear cookies
            document.cookie.split(";").forEach((cookie) => {
                const eqPos = cookie.indexOf("=");
                const name = eqPos > -1 ? cookie.substr(0, eqPos) : cookie;
                document.cookie = name + "=;expires=Thu, 01 Jan 1970 00:00:00 GMT;path=/";
            });
    
            console.log('Attempting login with email:', email);
            console.log('Password length:', password.length);
    
            const response = await account.createSession(email, password);
            console.log('Login successful', response);
            window.location.href = '/dashboard'; // Redirect to dashboard or another page
        } catch (err) {
            console.error('Login error:', err); // Log detailed error
            if (err.code === 400) {
                setError('Invalid email or password.');
            } else {
                setError('Login failed. Please check your credentials.');
            }
        }
    };
    

    return (
        <div className="login-container">
            <div className="login-box">
                <h2>Login</h2>
                {error && <p className="error-message">{error}</p>}
                <form onSubmit={handleLogin}>
                    <label htmlFor="email">Email</label>
                    <input
                        type="email"
                        id="email"
                        value={email}
                        onChange={(e) => setEmail(e.target.value)}
                        required
                    />
                    <label htmlFor="password">Password</label>
                    <input
                        type="password"
                        id="password"
                        value={password}
                        onChange={(e) => setPassword(e.target.value)}
                        required
                    />
                    <button type="submit">Login</button>
                </form>
                <p>
                    Don't have an account? <a href="/signup">Sign Up</a>
                </p>
            </div>
        </div>
    );
};

export default Login;
