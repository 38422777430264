import React, { useState, useEffect } from 'react';
import { account } from '../lib/appwrite';
import './Signup.css';

const Signup = () => {
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [name, setName] = useState('');
    const [error, setError] = useState('');

    const handleSignup = async (e) => {
        e.preventDefault();
        setError(''); // Clear previous errors

        try {
            const response = await account.create('unique()', email, password, name);
            console.log('Signup successful', response);
            localStorage.setItem('user', JSON.stringify({ name })); // Store user data
            window.location.href = '/login'; // Redirect to login or another page
        } catch (err) {
            console.error('Signup error:', err); // Log detailed error
            if (err.code === 409) {
                setError('An account with this email already exists.');
            } else {
                setError('Signup failed. Please try again.');
            }
        }
    };

    return (
        <div className="signup-container">
            <div className="signup-box">
                <h2>Sign Up</h2>
                {error && <p className="error-message">{error}</p>}
                <form onSubmit={handleSignup}>
                    <label htmlFor="name">Name</label>
                    <input
                        type="text"
                        id="name"
                        value={name}
                        onChange={(e) => setName(e.target.value)}
                        required
                    />
                    <label htmlFor="email">Email</label>
                    <input
                        type="email"
                        id="email"
                        value={email}
                        onChange={(e) => setEmail(e.target.value)}
                        required
                    />
                    <label htmlFor="password">Password</label>
                    <input
                        type="password"
                        id="password"
                        value={password}
                        onChange={(e) => setPassword(e.target.value)}
                        required
                    />
                    <button type="submit">Sign Up</button>
                </form>
                <p>
                    Already have an account? <a href="/login">Login</a>
                </p>
            </div>
        </div>
    );
};

export default Signup;
