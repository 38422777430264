import React, { useEffect } from 'react';
import { Link } from 'react-router-dom';
import AOS from 'aos';
import 'aos/dist/aos.css';
import { BiLogoInstagram, BiLogoFacebook, BiLogoTiktok, BiLogoDiscordAlt } from 'react-icons/bi';
import '../App.css';

function PricingPage() {
  useEffect(() => {
    AOS.init({
      duration: 1000, // animation duration in ms
      once: true, // whether animation should happen only once - while scrolling down
    });
  }, []);

  return (
    <div className="home-App">
        <div className="home-nav">
          <img src="logo192.png" alt="MindBloom Logo" />
          <h1>MindBloom</h1>
          <div className='home-index-buttons'>
          <Link className="home-index-links" to="/about">About</Link>
          <Link className="home-index-links" to="/">Pricing</Link>
          <Link className="home-index-links" to="/contact">Contact</Link>
          <Link className="home-index-links" to="/blogs">Blog</Link>
          </div>
          <div className="home-auth-buttons">
          <Link className="home-login-link" to="/login">
              Login
            </Link>
            <Link className="home-signup-link" to="/signup">
              Signup
            </Link>
          </div>
        </div>
      <div className="pricing-container">
        <header className="pricing-header">
          <h1 data-aos="fade-up">Choose Your Plan</h1>
          <p data-aos="fade-up">Find the right plan for you and start improving your focus today!</p>
        </header>
        <div className="pricing-cards">
        <div className="pricing-card">
  <h2>Pro</h2>
  <p className="price"><del>$12</del><span>/mo</span> $0</p>
  <ul>
    <li>Full access to the MindBloom dashboard</li>
    <li>Gamified task management</li>
    <li>Garden game</li>
    <li>ADHD Help</li>
    <li>Premium support</li>
    <li>Extra tools</li>
  </ul>
  <a href="/login" className="btn-signup" target="_blank" rel="noopener noreferrer">
  Get Started
  </a>
</div>
<div className="pricing-card">
  <h2>Donate</h2>
  <p className="price">$???</p>
  <ul>
    <li>Support the development</li>
    <li>Personal dedicated beta support</li>
    <li>Beta feature access</li>
    <li>Free future perks</li>
    <li>Extra entries to rewards and giveaways</li>
    <li>Be the one to help!</li>
  </ul>
  <a href="https://donate.stripe.com/9AQ9Ct58A0sf4gg3cd" className="btn-signup" target="_blank" rel="noopener noreferrer">
  Pay What you can
  </a>
</div>

        </div>
      </div>
      <footer className="footer">
        <div className="social-icons">
          <a href="https://instagram.com/mindbloomapp" target="_blank" rel="noopener noreferrer">
            <BiLogoInstagram />
          </a>
          <a href="https://facebook.com/mindbloomapp" target="_blank" rel="noopener noreferrer">
            <BiLogoFacebook />
          </a>
          <a href="https://tiktok.com/@mindbloomapp" target="_blank" rel="noopener noreferrer">
            <BiLogoTiktok />
          </a>
          <a href="https://discord.gg/73cSXYs86w" target="_blank" rel="noopener noreferrer">
            <BiLogoDiscordAlt />
          </a>
        </div>
        <div className="footer-links">
          <Link to="/terms">Terms of Service</Link>
          <Link to="/privacy">Privacy Policy</Link>
          <Link to="/legal">Legal</Link>
        </div>
        <p>&copy; 2024 MindBloom. All rights reserved.</p>
      </footer>
    </div>
    
  );
}

export default PricingPage;
