import React from 'react';
import { Link } from 'react-router-dom';
import AOS from 'aos';
import 'aos/dist/aos.css';
import { BiLogoInstagram, BiLogoFacebook, BiLogoTiktok, BiLogoDiscordAlt } from 'react-icons/bi';
import './Blog.css';
import '../../App.css';

const WhatsHavingADHDLike = () => {
  return (
    <div className='blog-app'>
      <div className="home-nav">
        <img src="logo192.png" alt="MindBloom Logo" />
        <h1>MindBloom</h1>
        <div className='home-index-buttons'>
          <Link className="home-index-links" to="/">About</Link>
          <Link className="home-index-links" to="/pricing">Pricing</Link>
          <Link className="home-index-links" to="/contact">Contact</Link>
        </div>
        <div className="home-auth-buttons">
          <Link className="home-login-link" to="/login">Login</Link>
          <Link className="home-signup-link" to="/signup">Signup</Link>
        </div>
      </div>

      <div className="blog-container">
        <h1 className="blog-title">What’s Having ADHD Like?</h1>
        <img src="/blogs/having-adhd.jpg" alt="Having ADHD" className="blog-header-image" />
        <div className="blog-content">
          <p>ADHD affects people in various ways, and each individual's experience is unique. In this blog, we’ll explore what it’s like to live with ADHD from different perspectives and offer insights into managing its impact.</p>

          <h2>1. Navigating Distractions</h2>
          <p>Living with ADHD often means dealing with frequent distractions. It can be challenging to stay focused on tasks, and managing distractions becomes a daily effort.</p>

          <h2>2. Experiencing Hyperfocus</h2>
          <p>ADHD can also lead to periods of hyperfocus, where individuals become deeply engrossed in a task. While this can be productive, it can also make it difficult to shift attention when needed.</p>

          <h2>3. Managing Time</h2>
          <p>Time management can be a struggle, with difficulties in estimating how long tasks will take and staying on schedule. Using timers and planning tools can help manage time more effectively.</p>

          <h2>4. Dealing with Emotional Regulation</h2>
          <p>Emotional regulation can be challenging, with intense feelings and mood swings. Techniques like mindfulness and therapy can help in managing emotions.</p>

          <h2>5. Finding Support and Strategies</h2>
          <p>Support from others and finding effective strategies are crucial. Seeking professional help, using organizational tools, and building a support network can make a significant difference.</p>

          <p>Understanding what it's like to have ADHD can foster empathy and improve strategies for managing its impact. MindBloom is here to provide support and resources for navigating ADHD challenges.</p>
        </div>
      </div>

      <footer className="footer">
        <div className="social-icons">
          <a href="https://instagram.com/mindbloomapp" target="_blank" rel="noopener noreferrer">
            <BiLogoInstagram />
          </a>
          <a href="https://facebook.com/mindbloomapp" target="_blank" rel="noopener noreferrer">
            <BiLogoFacebook />
          </a>
          <a href="https://tiktok.com/@mindbloomapp" target="_blank" rel="noopener noreferrer">
            <BiLogoTiktok />
          </a>
          <a href="https://discord.gg/73cSXYs86w" target="_blank" rel="noopener noreferrer">
            <BiLogoDiscordAlt />
          </a>
        </div>
        <div className="footer-links">
          <Link to="/terms">Terms of Service</Link>
          <Link to="/privacy">Privacy Policy</Link>
          <Link to="/legal">Legal</Link>
        </div>
        <p>&copy; 2024 MindBloom. All rights reserved.</p>
      </footer>
    </div>
  );
};

export default WhatsHavingADHDLike;
