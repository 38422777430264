import React, { useState, useEffect } from 'react';
import './Dashboard.css';

function Dashboard() {
    const [darkMode, setDarkMode] = useState(false);
    const [userName, setUserName] = useState('');
    const [objectives, setObjectives] = useState([
        { id: 1, name: 'Create and complete 3 tasks in the taskbar.', progress: 0, total: 3 },
        { id: 2, name: 'Use the schedule tool to outline your day.', progress: 1, total: 1 },
        { id: 3, name: 'Jot down 5 notes in the notepad', progress: 2, total: 5 }
    ]);

    useEffect(() => {
        // Fetch user data from local storage or state management on component mount
        const user = JSON.parse(localStorage.getItem('user'));
        if (user && user.name) {
            setUserName(user.name);
        }
    }, []);

    useEffect(() => {
        // Store userName in localStorage when it changes
        localStorage.setItem('user', JSON.stringify({ name: userName }));
    }, [userName]);

    const toggleDarkMode = () => {
        setDarkMode(!darkMode);
    };

    const handleComplete = (id) => {
        const updatedObjectives = objectives.map(obj => 
            obj.id === id ? { ...obj, progress: obj.total } : obj
        );
        setObjectives(updatedObjectives);
    };

    return (
        <div className={`dashboard-container ${darkMode ? 'dark' : 'light'}`}>
            <aside className="sidebar">
                <nav className="flex-1">
                    <ul>
                        <li><a href="/notes" className="sidebar-link">Notes</a></li>
                        <li><a href="/schedule" className="sidebar-link">Schedule</a></li>
                        <li><a href="/garden" className="sidebar-link">Garden</a></li>
                        <li><a href="/tasks" className="sidebar-link">Tasks</a></li>
                        <li><a href="/reminders" className="sidebar-link">Reminders</a></li>
                        <li><a href="/timer" className="sidebar-link">Timers</a></li>
                        <li><a href="/pomodoro" className="sidebar-link">Pomodoro</a></li>
                    </ul>
                </nav>
                <div className="sidebar-footer">
                    <button onClick={toggleDarkMode}>{darkMode ? 'Light Mode' : 'Dark Mode'}</button>
                </div>
            </aside>
            <div className="main-content">
                <header className="navbar">
                    <h1>MindBloom | Objectives</h1>
                    <nav>
                        <ul>
                            <li><a href="/" className="navbar-link">Home</a></li>
                            <li><a href="/about" className="navbar-link">About</a></li>
                            <li><a href="/contact" className="navbar-link">Contact</a></li>
                        </ul>
                    </nav>
                </header>
                <div className="content">
                    <h2>Welcome to your dashboard!</h2>
                    <p>Here you can manage your tasks, track your progress, and much more.</p>
                    <div className="objectives">
                        {objectives.map(obj => (
                            <div key={obj.id} className={`objective ${darkMode ? 'dark' : ''}`}>
                                <div className="objective-name">{obj.name}</div>
                                {obj.progress > 0 && (
                                    <div className="progress-bar-container">
                                        <div className="progress-bar">
                                            <div className="progress-bar-fill" style={{ width: `${(obj.progress / obj.total) * 100}%` }}></div>
                                        </div>
                                    </div>
                                )}
                                <div className="progress-text">{obj.progress}/{obj.total}</div>
                                <button
                                    className={`complete-button ${obj.progress === obj.total ? 'active' : ''}`}
                                    onClick={() => handleComplete(obj.id)}
                                    disabled={obj.progress !== obj.total}
                                >
                                    Complete
                                </button>
                            </div>
                        ))}
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Dashboard;
