import React, { useEffect } from 'react';
import { Link } from 'react-router-dom';
import AOS from 'aos';
import 'aos/dist/aos.css';
import { BiLogoInstagram, BiLogoFacebook, BiLogoTiktok, BiLogoDiscordAlt } from 'react-icons/bi';
import '../App.css';

function Home() {
  useEffect(() => {
    AOS.init({
      duration: 1000, // animation duration in ms
      once: true, // whether animation should happen only once - while scrolling down
    });
  }, []);

  
  return (
    <div className="home-App">
      <div className="home-nav">
        <img src="logo192.png" alt="MindBloom Logo" />
        <h1>MindBloom</h1>
        <div className='home-index-buttons'>
        <Link className="home-index-links" to="/about">About</Link>
          <Link className="home-index-links" to="/pricing">Pricing</Link>
          <Link className="home-index-links" to="/contact">Contact</Link>
          <Link className="home-index-links" to="/blogs">Blog</Link>
        </div>
        <div className="home-auth-buttons">
        <Link className="home-login-link" to="/login">
            Login
          </Link>
          <Link className="home-signup-link" to="/signup">
            Signup
          </Link>
        </div>
      </div>

      <header className="home-App-header">
        <h1 data-aos="fade-up">
        Level Up Your Focus: A <highlight>Proven</highlight> System To Finish Tasks <highlight-2>10x Faster</highlight-2>
        </h1>
        <p>60-70% of teens with ADHD may see significant improvements in organization, task management, and overall functioning when using structured scheduling and task management tools 🧠</p>
        <Link className="home-header-link" to="/login" data-aos="fade-up" data-aos-delay="200">
          Try MindBloom for free
        </Link>
        <p>Start your trial. Cancel any time</p>
      </header>

      <div class="home-App-body">
  <h1 data-aos="fade-up">Using vs Not using Mindbloom</h1>
  <div class="comparison-container">
    <div class="comparison-box">
      <h2>Not using MindBloom</h2>
      <ul>
        <li>❌ Low productivity</li>
        <li>❌ Bad organization</li>
        <li>❌ No Time management</li>
      </ul>
    </div>
    <div class="comparison-box our-product">
      <h2>Using MindBloom daily</h2>
      <ul>
        <li><check>✔</check> Improved productivity</li>
        <li><check>✔</check> Enhanced organization</li>
        <li><check>✔</check> Time management</li>
      </ul>
    </div>
  </div>
</div>


<footer className="footer">
        <div className="social-icons">
          <a href="https://instagram.com/mindbloomapp" target="_blank" rel="noopener noreferrer">
            <BiLogoInstagram />
          </a>
          <a href="https://facebook.com/mindbloomapp" target="_blank" rel="noopener noreferrer">
            <BiLogoFacebook />
          </a>
          <a href="https://tiktok.com/@mindbloomapp" target="_blank" rel="noopener noreferrer">
            <BiLogoTiktok />
          </a>
          <a href="https://discord.gg/73cSXYs86w" target="_blank" rel="noopener noreferrer">
            <BiLogoDiscordAlt />
          </a>
        </div>
        <div className="footer-links">
          <Link to="/terms">Terms of Service</Link>
          <Link to="/privacy">Privacy Policy</Link>
          <Link to="/legal">Legal</Link>
        </div>
        <p>&copy; 2024 MindBloom. All rights reserved.</p>
      </footer>
    </div>
  );
}

export default Home;
