import React from 'react';
import { Link } from 'react-router-dom';
import AOS from 'aos';
import 'aos/dist/aos.css';
import { BiLogoInstagram, BiLogoFacebook, BiLogoTiktok, BiLogoDiscordAlt } from 'react-icons/bi';
import './Blog.css';
import '../../App.css';

const HowADHDImpactsMyLife = () => {
  return (
    <div className='blog-app'>
      <div className="home-nav">
        <img src="logo192.png" alt="MindBloom Logo" />
        <h1>MindBloom</h1>
        <div className='home-index-buttons'>
          <Link className="home-index-links" to="/">About</Link>
          <Link className="home-index-links" to="/pricing">Pricing</Link>
          <Link className="home-index-links" to="/contact">Contact</Link>
        </div>
        <div className="home-auth-buttons">
          <Link className="home-login-link" to="/login">Login</Link>
          <Link className="home-signup-link" to="/signup">Signup</Link>
        </div>
      </div>

      <div className="blog-container">
        <h1 className="blog-title">How ADHD Impacts My Life</h1>
        <img src="/blogs/adhd-impact.jpg" alt="ADHD Impact" className="blog-header-image" />
        <div className="blog-content">
          <p>Living with ADHD can present unique challenges, but it also provides valuable insights into how we navigate the world. In this blog, I’ll share personal experiences and discuss the ways ADHD impacts daily life.</p>

          <h2>1. Challenges with Focus</h2>
          <p>ADHD often makes it difficult to maintain focus on tasks, leading to unfinished projects and missed deadlines. Strategies like breaking tasks into smaller steps and using visual reminders can help manage this challenge.</p>

          <h2>2. Managing Impulsivity</h2>
          <p>Impulsivity can lead to hasty decisions and difficulty in regulating emotions. Techniques like mindfulness and cognitive-behavioral strategies can assist in managing impulsivity.</p>

          <h2>3. Organizing Daily Tasks</h2>
          <p>Keeping track of daily tasks and responsibilities can be challenging. Utilizing organizational tools and creating structured routines can help improve organization and productivity.</p>

          <h2>4. Navigating Social Interactions</h2>
          <p>ADHD can affect social interactions, making it harder to follow conversations and respond appropriately. Practicing active listening and setting social goals can enhance communication skills.</p>

          <h2>5. Seeking Professional Help</h2>
          <p>Professional help can be crucial in managing ADHD. Therapy and medication can provide additional support and strategies for coping with symptoms.</p>

          <p>By understanding how ADHD impacts life, we can better address its challenges and develop effective strategies for managing it. Embrace the journey and continue to seek support and solutions.</p>
        </div>
      </div>

      <footer className="footer">
        <div className="social-icons">
          <a href="https://instagram.com/mindbloomapp" target="_blank" rel="noopener noreferrer">
            <BiLogoInstagram />
          </a>
          <a href="https://facebook.com/mindbloomapp" target="_blank" rel="noopener noreferrer">
            <BiLogoFacebook />
          </a>
          <a href="https://tiktok.com/@mindbloomapp" target="_blank" rel="noopener noreferrer">
            <BiLogoTiktok />
          </a>
          <a href="https://discord.gg/73cSXYs86w" target="_blank" rel="noopener noreferrer">
            <BiLogoDiscordAlt />
          </a>
        </div>
        <div className="footer-links">
          <Link to="/terms">Terms of Service</Link>
          <Link to="/privacy">Privacy Policy</Link>
          <Link to="/legal">Legal</Link>
        </div>
        <p>&copy; 2024 MindBloom. All rights reserved.</p>
      </footer>
    </div>
  );
};

export default HowADHDImpactsMyLife;
