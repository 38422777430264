import React, { useState, useEffect } from 'react';
import { Howl } from 'howler';
import './Dashboard.css';

const AudioPlayer = ({ track }) => {
    const [isPlaying, setIsPlaying] = useState(false);
    const [sound, setSound] = useState(null);

    useEffect(() => {
        if (sound) {
            sound.unload();
        }

        const newSound = new Howl({
            src: [track.src],
            html5: true, // Enable to stream large files
        });

        setSound(newSound);

        return () => {
            if (newSound) {
                newSound.unload();
            }
        };
    }, [track]);

    const handlePlayPause = () => {
        if (!sound) return;

        if (isPlaying) {
            sound.pause();
        } else {
            sound.play();
        }

        setIsPlaying(!isPlaying);
    };

    return (
        <div className="audio-player">
            <h2>{track.title}</h2>
            <button onClick={handlePlayPause}>
                {isPlaying ? 'Pause' : 'Play'}
            </button>
        </div>
    );
};

export default AudioPlayer;