import React, { useState, useEffect } from 'react';
import './Dashboard.css';
import withPremiumCheck from '../lib/withPremiumCheck'; // Adjust the path as needed

function Dashboard() {
  const [darkMode, setDarkMode] = useState(false);
  const [userName, setUserName] = useState('');
  const [plantStage, setPlantStage] = useState(1); // State for plant growth stage

  useEffect(() => {
    // Fetch user data from local storage or state management on component mount
    const user = JSON.parse(localStorage.getItem('user'));
    if (user && user.name) {
      setUserName(user.name);
    }
  }, []);

  useEffect(() => {
    // Store userName in localStorage when it changes
    localStorage.setItem('user', JSON.stringify({ name: userName }));
  }, [userName]);

  const toggleDarkMode = () => {
    setDarkMode(!darkMode);
  };

  // Function to handle plant growth
  const growPlant = () => {
    if (plantStage < 4) {
      setPlantStage(plantStage + 1);
    }
  };

  return (
    <div className={`dashboard-container ${darkMode ? 'dark' : 'light'}`}>
      <aside className="sidebar">
        <nav className="flex-1">
          <ul>
            <li><a href="/notes" className="sidebar-link">Notes</a></li>
            <li><a href="/schedule" className="sidebar-link">Schedule</a></li>
            <li><a href="/garden" className="current-sidebar-link">Garden</a></li>
            <li><a href="/tasks" className="sidebar-link">Tasks</a></li>
            <li><a href="/reminders" className="sidebar-link">Reminders</a></li>
            <li><a href="/timer" className="sidebar-link">Timers</a></li>
            <li><a href="/pomodoro" className="sidebar-link">Pomodoro</a></li>
          </ul>
        </nav>
        <div className="sidebar-footer">
          <button>{userName}</button>
        </div>
      </aside>
      <div className="main-content">
        <header className="navbar">
          <h1>MindBloom | Garden</h1>
          <nav>
            <ul>
              <li><a href="/" className="navbar-link">Home</a></li>
              <li><a href="/about" className="navbar-link">About</a></li>
              <li><a href="/contact" className="navbar-link">Contact</a></li>
            </ul>
          </nav>
        </header>
        <div className="content">
          <h2>Welcome to your dashboard!</h2>
          <p>Here you can manage your tasks, track your progress, and much more.</p>

          {/* Garden Section */}
          <div className="garden-section">
            <h2>Your Garden</h2>
            <div className="plant">
              <div 
                className={`plant-stage plant-stage-${plantStage}`} 
              ></div>
              <p>Plant Stage: {plantStage}</p>
              <button onClick={growPlant} className="grow-button">
                Grow Plant
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default withPremiumCheck(Dashboard);
