import './Blog.css';
import '../../App.css';
import React from 'react';
import { Link } from 'react-router-dom';
import AOS from 'aos';
import 'aos/dist/aos.css';
import { BiLogoInstagram, BiLogoFacebook, BiLogoTiktok, BiLogoDiscordAlt } from 'react-icons/bi';

const BlogPage = () => {
  return (
    <div className='blog-app'>
      <div className="home-nav">
      <img src="logo192.png" alt="MindBloom Logo" />
      <h1>MindBloom</h1>
        <div className='home-index-buttons'>
          <Link className="home-index-links" to="/">About</Link>
          <Link className="home-index-links" to="/pricing">Pricing</Link>
          <Link className="home-index-links" to="/contact">Contact</Link>
          <Link className="home-index-links" to="/blogs">Blog</Link>
        </div>
        <div className="home-auth-buttons">
          <Link className="home-login-link" to="/login">Login</Link>
          <Link className="home-signup-link" to="/signup">Signup</Link>
        </div>
      </div>

      <div className="blog-container">
        <h1 className="blog-title">Managing ADHD: Effective Strategies for Staying Organized and Focused</h1>
        
        <div className="blog-content">
          <h2>Introduction</h2>
          <p>At MindBloom, we know that living with ADHD can present unique challenges, especially when it comes to staying organized and maintaining focus. ADHD can make it difficult to manage time, keep track of tasks, and remain productive. This blog post will explore effective strategies and practical tips to help you navigate these challenges and thrive with ADHD.</p>
          
          <h2>Understanding ADHD and Organization</h2>
          <p>ADHD affects the brain's executive functions, making organization and time management particularly challenging. However, with the right strategies and tools, you can improve your organizational skills and maintain focus. Here are some practical tips to help you get started.</p>
          
          <h2>1. Create a Structured Routine</h2>
          <p>A structured routine can provide the stability and predictability needed to manage ADHD symptoms effectively. Here's how to build one:</p>
          
          <h3>Set a Daily Schedule:</h3>
          <p>Establish a consistent daily routine that includes specific times for waking up, meals, work or study, exercise, and relaxation. Stick to this schedule as closely as possible.</p>
          
          <h3>Break Tasks into Smaller Steps:</h3>
          <p>Large tasks can be overwhelming. Break them down into smaller, manageable steps, and tackle them one at a time.</p>
          
          <h3>Use Visual Aids:</h3>
          <p>Use calendars, planners, and to-do lists to keep track of tasks and deadlines. Visual aids can help you stay on top of your responsibilities and reduce forgetfulness.</p>
          
          <h2>2. Optimize Your Environment</h2>
          <p>Your environment plays a crucial role in managing ADHD symptoms. Here are some ways to optimize it for better focus and organization:</p>
          
          <h3>Minimize Distractions:</h3>
          <p>Create a workspace that is free from distractions. Keep your desk tidy, and remove items that might divert your attention.</p>
          
          <h3>Use Organizational Tools:</h3>
          <p>Utilize organizational tools such as bins, folders, and labels to keep your space clutter-free. Designate specific places for items to make them easy to find.</p>
          
          <h3>Incorporate Technology:</h3>
          <p>Leverage technology to stay organized. Use apps and digital tools designed for task management, reminders, and time tracking.</p>
          
          <h2>3. Develop Effective Time Management Skills</h2>
          <p>Time management is essential for staying on track with ADHD. Here are some strategies to help you manage your time effectively:</p>
          
          <h3>Prioritize Tasks:</h3>
          <p>Identify your most important tasks and prioritize them. Focus on completing high-priority tasks first before moving on to less critical ones.</p>
          
          <h3>Set Timers:</h3>
          <p>Use timers to allocate specific amounts of time to tasks. The Pomodoro Technique, which involves working for 25 minutes followed by a 5-minute break, can be particularly effective.</p>
          
          <h3>Avoid Multitasking:</h3>
          <p>Multitasking can reduce productivity and increase stress. Focus on one task at a time to ensure better quality and efficiency.</p>
          
          <h2>4. Build Healthy Habits</h2>
          <p>Healthy habits can significantly impact your ability to manage ADHD symptoms. Here are some habits to cultivate:</p>
          
          <h3>Exercise Regularly:</h3>
          <p>Physical activity can improve focus, reduce stress, and enhance overall well-being. Aim for at least 30 minutes of exercise most days of the week.</p>
          
          <h3>Maintain a Balanced Diet:</h3>
          <p>A balanced diet rich in fruits, vegetables, lean proteins, and whole grains can support brain health and improve concentration.</p>
          
          <h3>Get Enough Sleep:</h3>
          <p>Quality sleep is crucial for cognitive function and emotional regulation. Establish a regular sleep schedule and create a restful bedtime routine.</p>
          
          <h2>5. Seek Support and Resources</h2>
          <p>You don't have to manage ADHD alone. Here are some ways to seek support and resources:</p>
          
          <h3>Join Support Groups:</h3>
          <p>Connecting with others who have ADHD can provide valuable insights, encouragement, and a sense of community.</p>
          
          <h3>Work with a Coach or Therapist:</h3>
          <p>A professional coach or therapist can help you develop personalized strategies for managing ADHD and overcoming challenges.</p>
          
          <h3>Utilize MindBloom Tools:</h3>
          <p>At MindBloom, we offer a range of tools and resources designed to help individuals with ADHD stay organized and focused. Explore our platform to find solutions that work for you.</p>
          
          <h2>Conclusion</h2>
          <p>Managing ADHD requires a combination of effective strategies, healthy habits, and supportive resources. By implementing these tips, you can improve your organizational skills, maintain focus, and enhance your overall quality of life. At MindBloom, we're here to support you every step of the way. Start your journey with us today and discover the tools you need to thrive with ADHD.</p>
          <p>Ready to take control of your ADHD symptoms? Explore MindBloom's resources and tools designed to help you stay organized and focused. Join our community today and unlock your full potential!</p>
        </div>
      </div>
      
      <footer className="footer">
        <div className="social-icons">
          <a href="https://instagram.com/mindbloomapp" target="_blank" rel="noopener noreferrer">
            <BiLogoInstagram />
          </a>
          <a href="https://facebook.com/mindbloomapp" target="_blank" rel="noopener noreferrer">
            <BiLogoFacebook />
          </a>
          <a href="https://tiktok.com/@mindbloomapp" target="_blank" rel="noopener noreferrer">
            <BiLogoTiktok />
          </a>
          <a href="https://discord.gg/73cSXYs86w" target="_blank" rel="noopener noreferrer">
            <BiLogoDiscordAlt />
          </a>
        </div>
        <div className="footer-links">
          <Link to="/terms">Terms of Service</Link>
          <Link to="/privacy">Privacy Policy</Link>
          <Link to="/legal">Legal</Link>
        </div>
        <p>&copy; 2024 MindBloom. All rights reserved.</p>
      </footer>
    </div>
  );
};

export default BlogPage;
