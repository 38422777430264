import React, { useEffect } from 'react';
import { Link } from 'react-router-dom';
import AOS from 'aos';
import 'aos/dist/aos.css';
import { BiLogoInstagram, BiLogoFacebook, BiLogoTiktok, BiLogoDiscordAlt } from 'react-icons/bi';
import '../App.css';

function LegalPage() {
  useEffect(() => {
    AOS.init({
      duration: 1000, // animation duration in ms
      once: true, // whether animation should happen only once - while scrolling down
    });
  }, []);

  return (
    <div className="home-App">
      <div className="home-nav">
        <img src="logo192.png" alt="MindBloom Logo" />
        <h1>MindBloom</h1>
        <div className='home-index-buttons'>
          <Link className="home-index-links" to="/">About</Link>
          <Link className="home-index-links" to="/pricing">Pricing</Link>
          <Link className="home-index-links" to="/contact">Contact</Link>
          <Link className="home-index-links" to="/blog">Blog</Link>
        </div>
        <div className="home-auth-buttons">
          <Link className="home-login-link" to="/login">
            Login
          </Link>
          <Link className="home-signup-link" to="/signup">
            Signup
          </Link>
        </div>
      </div>
      <div className="legal-container">
        <header className="legal-header">
          <h1 data-aos="fade-up">Legal Information</h1>
          <p data-aos="fade-up">Understand the legal terms governing the use of MindBloom.</p>
        </header>
        <div className="legal-content" data-aos="fade-up">
          <section>
            <h2>Disclaimer</h2>
            <p>
              The information provided by MindBloom ("we," "us," or "our") on our website and mobile application is for general informational purposes only. All information on the Site and the application is provided in good faith; however, we make no representation or warranty of any kind, express or implied, regarding the accuracy, adequacy, validity, reliability, availability, or completeness of any information on the Site or our application.
            </p>
          </section>
          <section>
            <h2>Intellectual Property</h2>
            <p>
              The Site and our application contain material, including but not limited to software, text, graphics, and images (collectively, "Content"). The Content is protected by copyright laws. All rights, title, and interest in and to the Content remain with MindBloom or its licensors.
            </p>
            <p>
              You may not modify, publish, transmit, participate in the transfer or sale of, reproduce, create derivative works based on, distribute, perform, display, or in any way exploit any of the Content, in whole or in part, without the express prior written consent of MindBloom.
            </p>
          </section>
          <section>
            <h2>Limitation of Liability</h2>
            <p>
              To the fullest extent permitted by law, in no event shall MindBloom or its directors, employees, partners, agents, suppliers, or affiliates be liable for any indirect, incidental, special, consequential, or punitive damages, including without limitation, loss of profits, data, use, goodwill, or other intangible losses, resulting from (i) your use or inability to use the Service; (ii) any unauthorized access to or use of our servers and/or any personal information stored therein; (iii) any interruption or cessation of transmission to or from the Service.
            </p>
          </section>
          <section>
            <h2>Governing Law</h2>
            <p>
              These terms shall be governed and construed in accordance with the laws of the state or country in which MindBloom operates, without regard to its conflict of law provisions. Our failure to enforce any right or provision of these terms will not be considered a waiver of those rights. If any provision of these terms is held to be invalid or unenforceable by a court, the remaining provisions of these terms will remain in effect.
            </p>
          </section>
          <section>
            <h2>Changes to This Legal Notice</h2>
            <p>
              We reserve the right, at our sole discretion, to modify or replace these terms at any time. If a revision is material, we will provide at least 30 days' notice prior to any new terms taking effect. What constitutes a material change will be determined at our sole discretion.
            </p>
          </section>
          <section>
            <h2>Contact Us</h2>
            <p>
              If you have any questions about these Legal Notices, please contact us at legal@mindbloom.com.
            </p>
          </section>
        </div>
      </div>
      <footer className="footer">
        <div className="social-icons">
          <a href="https://instagram.com/mindbloomapp" target="_blank" rel="noopener noreferrer">
            <BiLogoInstagram />
          </a>
          <a href="https://facebook.com/mindbloomapp" target="_blank" rel="noopener noreferrer">
            <BiLogoFacebook />
          </a>
          <a href="https://tiktok.com/@mindbloomapp" target="_blank" rel="noopener noreferrer">
            <BiLogoTiktok />
          </a>
          <a href="https://discord.gg/73cSXYs86w" target="_blank" rel="noopener noreferrer">
            <BiLogoDiscordAlt />
          </a>
        </div>
        <div className="footer-links">
          <Link to="/terms">Terms of Service</Link>
          <Link to="/privacy">Privacy Policy</Link>
          <Link to="/legal">Legal</Link>
        </div>
        <p>&copy; 2024 MindBloom. All rights reserved.</p>
      </footer>
    </div>
  );
}

export default LegalPage;
