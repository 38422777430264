import React from 'react';
import { Link } from 'react-router-dom';
import AOS from 'aos';
import 'aos/dist/aos.css';
import { BiLogoInstagram, BiLogoFacebook, BiLogoTiktok, BiLogoDiscordAlt } from 'react-icons/bi';
import './blogHome.css';  // Custom styles for the blog home page
import '../../App.css';  // Custom styles for the blog home page

const blogPosts = [
  {
    title: "Understanding ADHD: A Guide to Self-Diagnosis and Seeking Professional Help",
    summary: "Learn about ADHD, its signs, and the steps for self-diagnosis. Understand the importance of seeking professional help for an accurate diagnosis and effective treatment.",
    url: "/understanding-adhd-self-diagnosis",
    image: "/blogs/seeking-professional-help.jpg",
  },
  {
    title: "Managing ADHD: Effective Strategies for Staying Organized and Focused",
    summary: "Discover effective time management strategies to boost productivity and achieve your goals while managing ADHD symptoms.",
    url: "/adhd-time-management-tips",
    image: "/blogs/staying-organized.jpg",
  },
  {
    title: "OCD: Understanding Symptoms and Finding Effective Coping Strategies",
    summary: "Get a deeper understanding of OCD, its symptoms, and effective coping strategies to manage the condition and improve your quality of life.",
    url: "/ocd-symptoms-coping-strategies",
    image: "/blogs/seeking-professional-help.jpg",
  },
  {
    title: "Navigating ADHD in the Workplace: Tips for Success",
    summary: "Explore the connection between diet and ADHD symptoms, and learn practical dietary tips to support your brain health and overall well-being.",
    url: "/navigating-adhd-tips-for-success",
    image: "/blogs/success.jpg",
  },
  {
    title: "How MindBloom Can Help You Achieve Your Goals and Manage ADHD and OCD",
    summary: "Discover how MindBloom empowers individuals with ADHD, OCD, and other organizational challenges to lead more productive and balanced lives through goal tracking, scheduling, note-taking, and more.",
    url: "/how-mindbloom-can-help-you",
    image: "/blogs/mindbloom-help.png",
  }
  
];

const BlogHomePage = () => {
  AOS.init(); // Initialize AOS for scroll animations

  return (
    <div className="blog-home-app">
      <div className="home-nav">
        <img src="logo192.png" alt="MindBloom Logo" />
        <h1>MindBloom</h1>
        <div className='home-index-buttons'>
          <Link className="home-index-links" to="/about">About</Link>
          <Link className="home-index-links" to="/pricing">Pricing</Link>
          <Link className="home-index-links" to="/contact">Contact</Link>
          <Link className="home-index-links" to="/">Blog</Link>

        </div>
        <div className="home-auth-buttons">
          <Link className="home-login-link" to="/login">Login</Link>
          <Link className="home-signup-link" to="/signup">Signup</Link>
        </div>
      </div>

      <div className="blog-home-container">
        <h1 className="blog-home-title">Welcome to the MindBloom Blog</h1>
        <p className="blog-home-subtitle">Explore our latest articles on ADHD and OCD management</p>

        <div className="blog-posts">
          {blogPosts.map((post, index) => (
            <div className="blog-post-card" key={index} data-aos="fade-up">
              <img src={post.image} alt={post.title} className="blog-post-image" />
              <h2 className="blog-post-title">{post.title}</h2>
              <p className="blog-post-summary">{post.summary}</p>
              <Link className="blog-post-link" to={post.url}>Read More</Link>
            </div>
          ))}
        </div>
      </div>

      <footer className="footer">
        <div className="social-icons">
          <a href="https://instagram.com/mindbloomapp" target="_blank" rel="noopener noreferrer">
            <BiLogoInstagram />
          </a>
          <a href="https://facebook.com/mindbloomapp" target="_blank" rel="noopener noreferrer">
            <BiLogoFacebook />
          </a>
          <a href="https://tiktok.com/@mindbloomapp" target="_blank" rel="noopener noreferrer">
            <BiLogoTiktok />
          </a>
          <a href="https://discord.gg/73cSXYs86w" target="_blank" rel="noopener noreferrer">
            <BiLogoDiscordAlt />
          </a>
        </div>
        <div className="footer-links">
          <Link to="/terms">Terms of Service</Link>
          <Link to="/privacy">Privacy Policy</Link>
          <Link to="/legal">Legal</Link>
        </div>
        <p>&copy; 2024 MindBloom. All rights reserved.</p>
      </footer>
    </div>
  );
};

export default BlogHomePage;
