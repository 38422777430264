import React from 'react';
import './App.css';

import Home from './pages/Home';
import Notes from './pages/Notes';
import Schedule from './pages/Schedule';
import Garden from './pages/Garden';
import Tasks from './pages/Tasks';
import Reminders from './pages/Reminders';
import Dashboard from './pages/Dashboard';
import Login from './pages/Login';
import Signup from './pages/Signup';
import Logout from './pages/Logout';
import Objective from './pages/Objectives';
import Timer from './pages/Timer';
import Pomodoro from './pages/Pomodoro';
import AudioPlayer from './pages/Audio';
import Plans from './pages/plans';
import RealSignup from './pages/RealSignup';
import Pricing from './pages/Pricing'; // Adjust the path as needed
import Contact from './pages/Contact'; // Adjust the path as needed
import About from './pages/About'; // Adjust the path as needed
import Donate from './pages/Donate'; // Adjust the path as needed
import Settings from './pages/Settings'; // Adjust the path as needed
import Terms from './pages/Terms.js'; // Adjust the path as needed
import Legal from './pages/Legal.js'; // Adjust the path as needed
import Privacy from './pages/Privacy.js'; // Adjust the path as needed

// Blogs
import Blogs from './pages/Blog/blogHome.js';
import BlogTemplate from './pages/Blog/template.js';
import UnderstandingADHD from './pages/Blog/understandingADHD.js';
import ManagingADHD from './pages/Blog/managingADHD.js';
import NavigatingADHD from './pages/Blog/navigatingADHD.js';
import CopingStrategies from './pages/Blog/copingStrategies.js';
import MindBloomCanHelp from './pages/Blog/mindbloomCanHelp.js';

import HowADHDImpactsMyLife from './pages/Blog/how-adhd-impacts-my-life.js';
import OwnYourADHD from './pages/Blog/own-your-adhd.js';
import WhatIsCopingMechanism from './pages/Blog/what-is-a-coping-mechanism.js';
import WhatIsDecisionFatigue from './pages/Blog/what-is-decision-fatigue.js';
import WhatsHavingADHDLike from './pages/Blog/whats-having-adhd-like.js';
import WhyRoutineImportantADHD from './pages/Blog/why-routine-important-adhd.js';

import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';

function App() {
  return (
    <Router>
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/dashboard" element={<Dashboard />} /> {/* Add the Dashboard route */}
        <Route path="/notes" element={<Notes />} /> {/* Add the Dashboard route */}
        <Route path="/schedule" element={<Schedule />} /> {/* Add the Dashboard route */}
        <Route path="/garden" element={<Garden />} /> {/* Add the Dashboard route */}
        <Route path="/tasks" element={<Tasks />} /> {/* Add the Dashboard route */}
        <Route path="/reminders" element={<Reminders />} /> {/* Add the Dashboard route */}
        <Route path="/signup" element={<Signup />} /> {/* Add the Dashboard route */}
        <Route path="/login" element={<Login />} /> {/* Add the Dashboard route */}
        <Route path="/logout" element={<Logout />} /> {/* Add the Dashboard route */}
        <Route path="/objectives" element={<Objective />} /> {/* Add the Dashboard route */}
        <Route path="/timer" element={<Timer />} /> {/* Add the Dashboard route */}
        <Route path="/pomodoro" element={<Pomodoro />} /> {/* Add the Dashboard route */}
        <Route path="/audioplayer" element={<AudioPlayer />} /> {/* Add the Dashboard route */}
        <Route path="/plans" element={<Plans />} /> {/* Add the Dashboard route */}
        <Route path="/realsignup" element={<RealSignup />} /> {/* Add the Dashboard route */}
        <Route path="/pricing" element={<Pricing />} /> {/* Add the Dashboard route */}
        <Route path="/contact" element={<Contact />} /> {/* Add the Dashboard route */}
        <Route path="/about" element={<About />} /> {/* Add the Dashboard route */}
        <Route path="/donate" element={<Donate />} /> {/* Add the Dashboard route */}
        <Route path="/settings" element={<Settings />} /> {/* Add the Dashboard route */}
        <Route path="/legal" element={<Legal />} /> {/* Add the Dashboard route */}
        <Route path="/privacy" element={<Privacy />} /> {/* Add the Dashboard route */}
        <Route path="/terms" element={<Terms />} /> {/* Add the Dashboard route */}



        <Route path="/blogs" element={<Blogs />} /> {/* Add the Dashboard route */}
        <Route path="/blog-template" element={<BlogTemplate />} /> {/* Add the Dashboard route */}

        <Route path="/understanding-adhd-self-diagnosis" element={<UnderstandingADHD />} /> {/* Add the Dashboard route */}
        <Route path="/adhd-time-management-tips" element={<ManagingADHD />} /> {/* Add the Dashboard route */}
        <Route path="/navigating-adhd-tips-for-success" element={<NavigatingADHD />} /> {/* Add the Dashboard route */}
        <Route path="/ocd-symptoms-coping-strategies" element={<CopingStrategies />} /> {/* Add the Dashboard route */}
        <Route path="/how-mindbloom-can-help-you" element={<MindBloomCanHelp />} /> {/* Add the Dashboard route */}

        <Route path="/why-routine-important-adhd" element={<WhyRoutineImportantADHD />} /> {/* Add the Dashboard route */}
        <Route path="/whats-having-adhd-like" element={<WhatsHavingADHDLike />} /> {/* Add the Dashboard route */}
        <Route path="/what-is-a-coping-mechanism" element={<WhatIsCopingMechanism />} /> {/* Add the Dashboard route */}
        <Route path="/what-is-decision-fatigue" element={<WhatIsDecisionFatigue />} /> {/* Add the Dashboard route */}
        <Route path="/how-adhd-impacts-my-life" element={<HowADHDImpactsMyLife />} /> {/* Add the Dashboard route */}
        <Route path="/own-your-adhd" element={<OwnYourADHD />} /> {/* Add the Dashboard route */}



      </Routes>
    </Router>
  );
}

export default App;
