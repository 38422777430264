import { Client, Account } from 'appwrite';

// Initialize the Appwrite client and account objects
const client = new Client();
client
  .setEndpoint('https://cloud.appwrite.io/v1') 
  .setProject('668c60c700398dc80f00');

const account = new Account(client);


// Function to check if user has the 'premium' label
async function checkPremiumLabel() {
  try {
    const user = await account.get();
    console.log('User details in checkPremiumLabel:', user);
    const labels = user.labels || [];
    return labels.includes('premium');
  } catch (error) {
    console.error('Failed to fetch user details in checkPremiumLabel', error);
    throw error;
  }
}

// Function to get the user ID from local storage
function getUserId() {
  return localStorage.getItem('userId');
}

export { client, account, checkPremiumLabel, getUserId };
