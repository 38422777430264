import React, { useEffect } from 'react';
import { Link } from 'react-router-dom';
import AOS from 'aos';
import 'aos/dist/aos.css';
import { BiLogoInstagram, BiLogoFacebook, BiLogoTiktok, BiLogoDiscordAlt } from 'react-icons/bi';
import '../App.css';

function AboutPage() {
  useEffect(() => {
    AOS.init({
      duration: 1000, // animation duration in ms
      once: true, // whether animation should happen only once - while scrolling down
    });
  }, []);

  return (
    <div className="home-App">
      <div className="home-nav">
        <img src="logo192.png" alt="MindBloom Logo" />
        <h1>MindBloom</h1>
        <div className='home-index-buttons'>
          <Link className="home-index-links" to="/">About</Link>
          <Link className="home-index-links" to="/pricing">Pricing</Link>
          <Link className="home-index-links" to="/contact">Contact</Link>
          <Link className="home-index-links" to="/blog">Blog</Link>

        </div>
        <div className="home-auth-buttons">
          <Link className="home-login-link" to="/login">
            Login
          </Link>
          <Link className="home-signup-link" to="/signup">
            Signup
          </Link>
        </div>
      </div>
      <div className="about-container">
        <header className="about-header">
          <h1 data-aos="fade-up">About MindBloom</h1>
          <p data-aos="fade-up">Learn more about our mission and how we aim to help you stay organized and focused.</p>
        </header>
        <div className="about-content" data-aos="fade-up">
          <section>
            <h2>Our Mission</h2>
            <p>
              At MindBloom, our mission is to provide a comprehensive tool that helps individuals with ADHD, OCD, or difficulty staying focused and organized. We believe in the power of gamification and positive reinforcement to make daily tasks more manageable and rewarding.
            </p>
          </section>
          <section>
            <h2>Our Features</h2>
            <ul>
              <li>Gamified task management</li>
              <li>Interactive garden game</li>
              <li>Personalized goal tracking</li>
              <li>Comprehensive scheduling</li>
              <li>Effective note-taking</li>
              <li>ADHD and OCD specific tools</li>
            </ul>
          </section>
          <section>
            <h2>Our Story</h2>
            <p>
              MindBloom was created out of a desire to help people who struggle with staying organized and focused. Our founders, who have personal experience with ADHD, wanted to develop a tool that makes organization and task management enjoyable and effective.
            </p>
          </section>
        </div>
      </div>
      <footer className="footer">
        <div className="social-icons">
          <a href="https://instagram.com/mindbloomapp" target="_blank" rel="noopener noreferrer">
            <BiLogoInstagram />
          </a>
          <a href="https://facebook.com/mindbloomapp" target="_blank" rel="noopener noreferrer">
            <BiLogoFacebook />
          </a>
          <a href="https://tiktok.com/@mindbloomapp" target="_blank" rel="noopener noreferrer">
            <BiLogoTiktok />
          </a>
          <a href="https://discord.gg/73cSXYs86w" target="_blank" rel="noopener noreferrer">
            <BiLogoDiscordAlt />
          </a>
        </div>
        <div className="footer-links">
          <Link to="/terms">Terms of Service</Link>
          <Link to="/privacy">Privacy Policy</Link>
          <Link to="/legal">Legal</Link>
        </div>
        <p>&copy; 2024 MindBloom. All rights reserved.</p>
      </footer>
    </div>
  );
}

export default AboutPage;
