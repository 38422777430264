import React from 'react';
import { useNavigate } from 'react-router-dom';

const PremiumError = () => {
    const navigate = useNavigate();

    const handleRedirect = () => {
        navigate('/pricing');
    };

    return (
        <div className="min-h-screen flex flex-col items-center justify-center bg-gray-100 text-gray-800 p-4">
            <div className="max-w-md w-full bg-white shadow-lg rounded-lg p-6">
                <h1 className="text-2xl font-semibold text-red-600 mb-4">Premium Access Required</h1>
                <p className="text-lg mb-6">Oops! It looks like you need a premium subscription to access this page.</p>
                <button
                    onClick={handleRedirect}
                    className="bg-blue-600 text-white px-4 py-2 rounded-lg hover:bg-blue-500 transition"
                >
                    Upgrade to Premium
                </button>
            </div>
        </div>
    );
};

export default PremiumError;
