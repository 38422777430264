import React from 'react';
import { Link } from 'react-router-dom';
import AOS from 'aos';
import 'aos/dist/aos.css';
import { BiLogoInstagram, BiLogoFacebook, BiLogoTiktok, BiLogoDiscordAlt } from 'react-icons/bi';
import './Blog.css';
import '../../App.css';

const WhatIsCopingMechanism = () => {
  return (
    <div className='blog-app'>
      <div className="home-nav">
        <img src="logo192.png" alt="MindBloom Logo" />
        <h1>MindBloom</h1>
        <div className='home-index-buttons'>
          <Link className="home-index-links" to="/">About</Link>
          <Link className="home-index-links" to="/pricing">Pricing</Link>
          <Link className="home-index-links" to="/contact">Contact</Link>
        </div>
        <div className="home-auth-buttons">
          <Link className="home-login-link" to="/login">Login</Link>
          <Link className="home-signup-link" to="/signup">Signup</Link>
        </div>
      </div>

      <div className="blog-container">
        <h1 className="blog-title">What is a Coping Mechanism?</h1>
        <img src="/blogs/coping-mechanism.jpg" alt="Coping Mechanism" className="blog-header-image" />
        <div className="blog-content">
          <p>Coping mechanisms are strategies or techniques used to manage stress, emotions, and challenging situations. They help individuals handle difficulties and improve overall well-being. In this blog, we’ll explore different types of coping mechanisms and their benefits.</p>

          <h2>1. What Are Coping Mechanisms?</h2>
          <p>Coping mechanisms are actions or methods used to deal with stress or emotional discomfort. They can be positive or negative, and they help manage the impact of stressors.</p>

          <h2>2. Types of Coping Mechanisms</h2>
          <p>There are various types of coping mechanisms, including:</p>
          <ul>
            <li><strong>Problem-Focused Coping:</strong> Addressing the problem directly to reduce its impact.</li>
            <li><strong>Emotion-Focused Coping:</strong> Managing emotions related to the problem.</li>
            <li><strong>Cognitive Coping:</strong> Changing thought patterns to handle stress more effectively.</li>
            <li><strong>Social Support:</strong> Seeking help from friends, family, or support groups.</li>
          </ul>

          <h2>3. Benefits of Effective Coping</h2>
          <p>Effective coping mechanisms can improve mental health, increase resilience, and enhance overall quality of life. They help individuals navigate stress and challenges more effectively.</p>

          <h2>4. Developing Personal Coping Strategies</h2>
          <p>Developing personal coping strategies involves identifying what works best for you and incorporating these techniques into your routine. This might include mindfulness, exercise, or seeking professional help.</p>

          <h2>5. When to Seek Help</h2>
          <p>If coping mechanisms are not sufficient or if stress becomes overwhelming, seeking professional help is important. Therapists and counselors can provide additional support and guidance.</p>

          <p>Coping mechanisms are essential tools for managing stress and enhancing well-being. MindBloom offers resources and tools to support you in developing effective coping strategies.</p>
        </div>
      </div>

      <footer className="footer">
        <div className="social-icons">
          <a href="https://instagram.com/mindbloomapp" target="_blank" rel="noopener noreferrer">
            <BiLogoInstagram />
          </a>
          <a href="https://facebook.com/mindbloomapp" target="_blank" rel="noopener noreferrer">
            <BiLogoFacebook />
          </a>
          <a href="https://tiktok.com/@mindbloomapp" target="_blank" rel="noopener noreferrer">
            <BiLogoTiktok />
          </a>
          <a href="https://discord.gg/73cSXYs86w" target="_blank" rel="noopener noreferrer">
            <BiLogoDiscordAlt />
          </a>
        </div>
        <div className="footer-links">
          <Link to="/terms">Terms of Service</Link>
          <Link to="/privacy">Privacy Policy</Link>
          <Link to="/legal">Legal</Link>
        </div>
        <p>&copy; 2024 MindBloom. All rights reserved.</p>
      </footer>
    </div>
  );
};

export default WhatIsCopingMechanism;
