import './Blog.css';
import '../../App.css';
import React from 'react';
import { Link } from 'react-router-dom';
import AOS from 'aos';
import 'aos/dist/aos.css';
import { BiLogoInstagram, BiLogoFacebook, BiLogoTiktok, BiLogoDiscordAlt } from 'react-icons/bi';

const BlogPageTemplate = () => {

  return (
    <div className='blog-app'>
      {/* Navigation Bar */}
      <div className="home-nav">
        <img src="logo192.png" alt="MindBloom Logo" />
        <h1>MindBloom</h1>
        <div className='home-index-buttons'>
          <Link className="home-index-links" to="/">About</Link>
          <Link className="home-index-links" to="/pricing">Pricing</Link>
          <Link className="home-index-links" to="/contact">Contact</Link>
        </div>
        <div className="home-auth-buttons">
          <Link className="home-login-link" to="/login">Login</Link>
          <Link className="home-signup-link" to="/signup">Signup</Link>
        </div>
      </div>

      {/* Blog Content */}
      <div className="blog-container">
        <h1 className="blog-title">[Blog Title Here]</h1>
        
        <div className="blog-content">
          {/* Introduction */}
          <h2>Introduction</h2>
          <p>[Introduction Content Here]</p>

          {/* Section 1 */}
          <h2>[Section 1 Title]</h2>
          <p>[Section 1 Content]</p>
          
          {/* Section 2 */}
          <h2>[Section 2 Title]</h2>
          <p>[Section 2 Content]</p>

          {/* Add more sections as needed */}
          
          {/* Conclusion */}
          <h2>Conclusion</h2>
          <p>[Conclusion Content Here]</p>
        </div>
      </div>
      
      {/* Footer */}
      <footer className="footer">
        <div className="social-icons">
          <a href="https://instagram.com/mindbloomapp" target="_blank" rel="noopener noreferrer">
            <BiLogoInstagram />
          </a>
          <a href="https://facebook.com/mindbloomapp" target="_blank" rel="noopener noreferrer">
            <BiLogoFacebook />
          </a>
          <a href="https://tiktok.com/@mindbloomapp" target="_blank" rel="noopener noreferrer">
            <BiLogoTiktok />
          </a>
          <a href="https://discord.gg/73cSXYs86w" target="_blank" rel="noopener noreferrer">
            <BiLogoDiscordAlt />
          </a>
        </div>
        <div className="footer-links">
          <Link to="/terms">Terms of Service</Link>
          <Link to="/privacy">Privacy Policy</Link>
          <Link to="/legal">Legal</Link>
        </div>
        <p>&copy; 2024 MindBloom. All rights reserved.</p>
      </footer>
    </div>
  );
};

export default BlogPageTemplate;