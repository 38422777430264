import React, { useState } from 'react';
import './Settings.css';

const themes = [
  { name: 'Light', colors: ['#ffffff', '#f0f0f0', '#d9d9d9'] },
  { name: 'Dark', colors: ['#000000', '#333333', '#666666'] },
  { name: 'Blue', colors: ['#2196F3', '#64B5F6', '#BBDEFB'] },
];

const AccountSettings = () => {
  const [selectedTheme, setSelectedTheme] = useState('');

  const handleChooseTheme = (themeName) => {
    setSelectedTheme(themeName);
    document.cookie = `theme=${themeName}; path=/;`;
  };

  return (
    <div className="account-settings-container">
      <h1>Choose Your Theme</h1>
      <div className="themes-container">
        {themes.map((theme) => (
          <div key={theme.name} className="theme-card">
            <div className="theme-colors">
              {theme.colors.map((color, index) => (
                <div key={index} className="color-swatch" style={{ backgroundColor: color }}></div>
              ))}
            </div>
            <h2>{theme.name}</h2>
            <button
              className={`choose-button ${selectedTheme === theme.name ? 'selected' : ''}`}
              onClick={() => handleChooseTheme(theme.name)}
            >
              {selectedTheme === theme.name ? 'Chosen' : 'Choose'}
            </button>
          </div>
        ))}
      </div>
    </div>
  );
};

export default AccountSettings;
