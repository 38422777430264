import React, { useState } from 'react';
import { loadStripe } from '@stripe/stripe-js';
import { Elements, CardElement, useStripe, useElements } from '@stripe/react-stripe-js';
import '../App.css';

// Load your Stripe publishable key
const stripePromise = loadStripe('pk_live_51PdOqDKeHvWeWQkV8oB6jfd8iOxkM7MO56UlBX67SOyImC442HR53py7eqxbBfxBUOxwNGtexGQ0iNVSpQUgfXWQ009BWdRMZC');

const CheckoutForm = () => {
  const stripe = useStripe();
  const elements = useElements();
  const [errorMessage, setErrorMessage] = useState(null);

  const handleSubmit = async (event) => {
    event.preventDefault();

    if (!stripe || !elements) {
      return;
    }

    const card = elements.getElement(CardElement);

    const result = await stripe.createToken(card);

    if (result.error) {
      setErrorMessage(result.error.message);
    } else {
      // Send the token to your server for processing the payment
      console.log(result.token);
    }
  };

  return (
    <form onSubmit={handleSubmit} className="payment-form">
      <CardElement />
      <button type="submit" disabled={!stripe} className="payment-button">
        Pay
      </button>
      {errorMessage && <div className="payment-error">{errorMessage}</div>}
    </form>
  );
};

const Payment = () => {
  return (
    <Elements stripe={stripePromise}>
      <div className="payment-page">
        <h1>Payment Page</h1>
        <CheckoutForm />
      </div>
    </Elements>
  );
};

export default Payment;
