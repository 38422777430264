import React from 'react';
import { Link } from 'react-router-dom';
import AOS from 'aos';
import 'aos/dist/aos.css';
import { BiLogoInstagram, BiLogoFacebook, BiLogoTiktok, BiLogoDiscordAlt } from 'react-icons/bi';
import './Blog.css';
import '../../App.css';

const WhyRoutineImportantADHD = () => {
  return (
    <div className='blog-app'>
      <div className="home-nav">
        <img src="logo192.png" alt="MindBloom Logo" />
        <h1>MindBloom</h1>
        <div className='home-index-buttons'>
          <Link className="home-index-links" to="/">About</Link>
          <Link className="home-index-links" to="/pricing">Pricing</Link>
          <Link className="home-index-links" to="/contact">Contact</Link>
        </div>
        <div className="home-auth-buttons">
          <Link className="home-login-link" to="/login">Login</Link>
          <Link className="home-signup-link" to="/signup">Signup</Link>
        </div>
      </div>

      <div className="blog-container">
        <h1 className="blog-title">Why is Routine So Important to Someone with ADHD?</h1>
        <img src="/blogs/routine-adhd.jpg" alt="Routine and ADHD" className="blog-header-image" />
        <div className="blog-content">
          <p>Routine plays a crucial role in managing ADHD. It helps create structure, reduce stress, and improve focus. In this blog, we’ll explore why routines are so important for individuals with ADHD and how to build an effective routine.</p>

          <h2>1. Creating Structure</h2>
          <p>Routine provides a structured environment, which can help individuals with ADHD stay organized and manage their time more effectively.</p>

          <h2>2. Reducing Stress</h2>
          <p>Predictable routines can reduce stress by minimizing the number of decisions and surprises in daily life. This can lead to improved mental well-being.</p>

          <h2>3. Improving Focus</h2>
          <p>Routines can enhance focus by establishing clear expectations and reducing distractions. Consistent habits can help maintain attention on tasks.</p>

          <h2>4. Building Good Habits</h2>
          <p>Consistent routines help build good habits and reinforce positive behaviors. This can lead to increased productivity and success in managing ADHD symptoms.</p>

          <h2>5. Flexibility and Adaptation</h2>
          <p>While routines are important, flexibility is also key. Allowing for adjustments and adapting routines as needed can help accommodate changes and challenges.</p>

          <p>Developing and maintaining a routine can significantly improve the quality of life for individuals with ADHD. At MindBloom, we offer tools to help you create and stick to routines that work for you.</p>
        </div>
      </div>

      <footer className="footer">
        <div className="social-icons">
          <a href="https://instagram.com/mindbloomapp" target="_blank" rel="noopener noreferrer">
            <BiLogoInstagram />
          </a>
          <a href="https://facebook.com/mindbloomapp" target="_blank" rel="noopener noreferrer">
            <BiLogoFacebook />
          </a>
          <a href="https://tiktok.com/@mindbloomapp" target="_blank" rel="noopener noreferrer">
            <BiLogoTiktok />
          </a>
          <a href="https://discord.gg/73cSXYs86w" target="_blank" rel="noopener noreferrer">
            <BiLogoDiscordAlt />
          </a>
        </div>
        <div className="footer-links">
          <Link to="/terms">Terms of Service</Link>
          <Link to="/privacy">Privacy Policy</Link>
          <Link to="/legal">Legal</Link>
        </div>
        <p>&copy; 2024 MindBloom. All rights reserved.</p>
      </footer>
    </div>
  );
};

export default WhyRoutineImportantADHD;
