import React, { useEffect } from 'react';
import { Link } from 'react-router-dom';
import AOS from 'aos';
import 'aos/dist/aos.css';
import { BiLogoInstagram, BiLogoFacebook, BiLogoTiktok, BiLogoDiscordAlt } from 'react-icons/bi';
import '../App.css';

function PrivacyPolicyPage() {
  useEffect(() => {
    AOS.init({
      duration: 1000, // animation duration in ms
      once: true, // whether animation should happen only once - while scrolling down
    });
  }, []);

  return (
    <div className="home-App">
      <div className="home-nav">
        <img src="logo192.png" alt="MindBloom Logo" />
        <h1>MindBloom</h1>
        <div className='home-index-buttons'>
          <Link className="home-index-links" to="/">About</Link>
          <Link className="home-index-links" to="/pricing">Pricing</Link>
          <Link className="home-index-links" to="/contact">Contact</Link>
          <Link className="home-index-links" to="/blog">Blog</Link>
        </div>
        <div className="home-auth-buttons">
          <Link className="home-login-link" to="/login">
            Login
          </Link>
          <Link className="home-signup-link" to="/signup">
            Signup
          </Link>
        </div>
      </div>
      <div className="privacy-container">
        <header className="privacy-header">
          <h1 data-aos="fade-up">Privacy Policy</h1>
          <p data-aos="fade-up">Your privacy is important to us. Learn how we handle your information.</p>
        </header>
        <div className="privacy-content" data-aos="fade-up">
          <section>
            <h2>Introduction</h2>
            <p>
              At MindBloom, we are committed to protecting your privacy. This Privacy Policy explains how we collect, use, disclose, and safeguard your information when you visit our website and use our services.
            </p>
          </section>
          <section>
            <h2>Information We Collect</h2>
            <p>
              We collect information that you provide directly to us, such as when you create an account, update your profile, use interactive features of our services, participate in surveys, or communicate with us.
            </p>
            <ul>
              <li>Personal Identification Information (Name, Email Address, etc.)</li>
              <li>Usage Data (Pages Visited, Time Spent, etc.)</li>
              <li>Cookies and Tracking Technologies</li>
            </ul>
          </section>
          <section>
            <h2>How We Use Your Information</h2>
            <p>
              The information we collect from you may be used in the following ways:
            </p>
            <ul>
              <li>To personalize your experience</li>
              <li>To improve our website and services</li>
              <li>To process transactions</li>
              <li>To send periodic emails regarding your account or other products and services</li>
              <li>To respond to your inquiries and support needs</li>
            </ul>
          </section>
          <section>
            <h2>Sharing Your Information</h2>
            <p>
              We do not sell, trade, or otherwise transfer to outside parties your Personally Identifiable Information unless we provide users with advance notice. This does not include website hosting partners and other parties who assist us in operating our website, conducting our business, or serving our users, so long as those parties agree to keep this information confidential.
            </p>
          </section>
          <section>
            <h2>Security of Your Information</h2>
            <p>
              We use administrative, technical, and physical security measures to protect your personal information. While we have taken reasonable steps to secure the personal information you provide to us, please be aware that despite our efforts, no security measures are perfect or impenetrable.
            </p>
          </section>
          <section>
            <h2>Your Privacy Rights</h2>
            <p>
              Depending on your location, you may have the right to request access to the personal information we collect from you, change that information, or delete it. If you would like to exercise these rights, please contact us at support@mindbloom.com.
            </p>
          </section>
          <section>
            <h2>Changes to This Privacy Policy</h2>
            <p>
              We may update this Privacy Policy from time to time. We will notify you of any changes by posting the new Privacy Policy on this page. You are advised to review this Privacy Policy periodically for any changes.
            </p>
          </section>
          <section>
            <h2>Contact Us</h2>
            <p>
              If you have any questions about this Privacy Policy, please contact us at support@mindbloom.com.
            </p>
          </section>
        </div>
      </div>
      <footer className="footer">
        <div className="social-icons">
          <a href="https://instagram.com/mindbloomapp" target="_blank" rel="noopener noreferrer">
            <BiLogoInstagram />
          </a>
          <a href="https://facebook.com/mindbloomapp" target="_blank" rel="noopener noreferrer">
            <BiLogoFacebook />
          </a>
          <a href="https://tiktok.com/@mindbloomapp" target="_blank" rel="noopener noreferrer">
            <BiLogoTiktok />
          </a>
          <a href="https://discord.gg/73cSXYs86w" target="_blank" rel="noopener noreferrer">
            <BiLogoDiscordAlt />
          </a>
        </div>
        <div className="footer-links">
          <Link to="/terms">Terms of Service</Link>
          <Link to="/privacy">Privacy Policy</Link>
          <Link to="/legal">Legal</Link>
        </div>
        <p>&copy; 2024 MindBloom. All rights reserved.</p>
      </footer>
    </div>
  );
}

export default PrivacyPolicyPage;
