import React, { useState, useEffect } from 'react';
import { Client, Databases, Account, Query } from 'appwrite'; // Import Query
import './Dashboard.css';

const client = new Client()
  .setEndpoint('https://cloud.appwrite.io/v1')
  .setProject('668c60c700398dc80f00');

const databases = new Databases(client);
const account = new Account(client);

function Tasks() {
  const [darkMode, setDarkMode] = useState(false);
  const [tasks, setTasks] = useState([]);
  const [newTask, setNewTask] = useState('');
  const [userName, setUserName] = useState('');

  useEffect(() => {
    const fetchUserData = async () => {
      try {
        const user = JSON.parse(localStorage.getItem('user'));
        if (user && user.name) {
          setUserName(user.name);
        }
      } catch (error) {
        console.error('Error fetching user data:', error.message);
      }
    };

    fetchUserData();
  }, []);

  useEffect(() => {
    localStorage.setItem('user', JSON.stringify({ name: userName }));
  }, [userName]);

  const toggleDarkMode = () => {
    setDarkMode(!darkMode);
  };

  const handleAddTask = async () => {
    if (newTask.trim() !== '') {
      try {
        const user = JSON.parse(localStorage.getItem('user'));
        if (!user || !user.id) {
          console.error('User ID is not defined');
          return;
        }

        const response = await databases.createDocument(
          '6690622800253f723a68', // Database ID
          '6692c45800189536494b', // Collection ID
          'unique()',
          {
            text: newTask,
            completed: false,
            userId: user.id
          }
        );
        setTasks([...tasks, { ...response, id: response.$id }]);
        setNewTask('');
      } catch (error) {
        console.error('Error creating task:', error.message);
      }
    }
  };

  const handleToggleTaskCompletion = async (taskId) => {
    try {
      const task = tasks.find(task => task.id === taskId);
      if (task) {
        const updatedCompletedStatus = !task.completed;
        await databases.updateDocument(
          '6690622800253f723a68', // Database ID
          '6692c45800189536494b', // Collection ID
          taskId,
          { completed: updatedCompletedStatus }
        );
        setTasks(tasks.map(t =>
          t.id === taskId ? { ...t, completed: updatedCompletedStatus } : t
        ));
      }
    } catch (error) {
      console.error('Error toggling task completion:', error.message);
    }
  };

  const handleDeleteTask = async (taskId) => {
    try {
      await databases.deleteDocument(
        '6690622800253f723a68', // Database ID
        '6692c45800189536494b', // Collection ID
        taskId
      );
      setTasks(tasks.filter(task => task.id !== taskId));
    } catch (error) {
      console.error('Error deleting task:', error.message);
    }
  };

  // Separate tasks into completed and pending
  const pendingTasks = tasks.filter(task => !task.completed);
  const completedTasks = tasks.filter(task => task.completed);

  return (
    <div className={`dashboard-container ${darkMode ? 'dark' : 'light'}`}>
      <aside className="sidebar">
        <nav className="flex-1">
          <ul>
            <li><a href="/notes" className="sidebar-link">Notes</a></li>
            <li><a href="/schedule" className="sidebar-link">Schedule</a></li>
            <li><a href="/garden" className="sidebar-link">Garden</a></li>
            <li><a href="/tasks" className="current-sidebar-link">Tasks</a></li>
            <li><a href="/reminders" className="sidebar-link">Reminders</a></li>
            <li><a href="/timer" className="sidebar-link">Timers</a></li>
            <li><a href="/pomodoro" className="sidebar-link">Pomodoro</a></li>
          </ul>
        </nav>
        <div className="sidebar-footer">
          <button>{userName}</button>
        </div>
      </aside>
      <div className="main-content">
        <header className="navbar">
          <h1>MindBloom | Tasks</h1>
          <nav>
            <ul>
              <li><a href="/" className="navbar-link">Home</a></li>
              <li><a href="/about" className="navbar-link">About</a></li>
              <li><a href="/contact" className="navbar-link">Contact</a></li>
            </ul>
          </nav>
        </header>
        <div className="content">
          <h2>Welcome to your task manager!</h2>
          <p>Here you can manage your tasks, track your progress, and more.</p>
          <div className="task-manager">
            <input 
              type="text" 
              value={newTask} 
              onChange={(e) => setNewTask(e.target.value)} 
              placeholder="Try something like: Complete my daily workout."
            />
            <button onClick={handleAddTask}>Add Task</button>
          </div>
          <ul className="task-list">
            {pendingTasks.map(task => (
              <li key={task.id} className={task.completed ? 'complete-animation' : ''}>
                <span>{task.text}</span>
                <div className="task-actions">
                  <button className="complete" onClick={() => handleToggleTaskCompletion(task.id)}>Complete</button>
                  <button onClick={() => handleDeleteTask(task.id)}>Delete</button>
                </div>
              </li>
            ))}
            {completedTasks.map(task => (
              <li key={task.id} className="completed-task">
                <span>{task.text}</span>
                <div className="task-actions">
                  <button className="complete" onClick={() => handleToggleTaskCompletion(task.id)}>Undo</button>
                  <button onClick={() => handleDeleteTask(task.id)}>Delete</button>
                </div>
              </li>
            ))}
          </ul>
        </div>
      </div>
    </div>
  );
}

export default Tasks;
