import React, { useState, useEffect } from 'react';
import './Dashboard.css';

function Dashboard() {
  const [darkMode, setDarkMode] = useState(false);
  const [reminders, setReminders] = useState([]);
  const [currentReminder, setCurrentReminder] = useState({ id: null, title: '', description: '', date: '' });
  const [showAddPopup, setShowAddPopup] = useState(false);
  const [showEditPopup, setShowEditPopup] = useState(false);
  const [userName, setUserName] = useState('');

  useEffect(() => {
      // Fetch user data from local storage or state management on component mount
      const user = JSON.parse(localStorage.getItem('user'));
      if (user && user.name) {
          setUserName(user.name);
      }
  }, []);

  useEffect(() => {
      // Store userName in localStorage when it changes
      localStorage.setItem('user', JSON.stringify({ name: userName }));
  }, [userName]);

  const toggleDarkMode = () => {
    setDarkMode(!darkMode);
  };

  const openAddPopup = () => {
    setCurrentReminder({ id: null, title: '', description: '', date: '' });
    setShowAddPopup(true);
  };

  const closeAddPopup = () => {
    setShowAddPopup(false);
  };

  const openEditPopup = (reminder) => {
    setCurrentReminder(reminder);
    setShowEditPopup(true);
  };

  const closeEditPopup = () => {
    setCurrentReminder({ id: null, title: '', description: '', date: '' });
    setShowEditPopup(false);
  };

  const handlePopupChange = (e) => {
    const { name, value } = e.target;
    setCurrentReminder((prevReminder) => ({
      ...prevReminder,
      [name]: value,
    }));
  };

  const handleAddReminder = () => {
    const newId = reminders.length + 1;
    const newReminder = { ...currentReminder, id: newId };
    setReminders([...reminders, newReminder]);
    setCurrentReminder({ id: null, title: '', description: '', date: '' });
    setShowAddPopup(false);
  };

  const handleEditReminder = () => {
    const updatedReminders = reminders.map((reminder) =>
      reminder.id === currentReminder.id ? currentReminder : reminder
    );
    setReminders(updatedReminders);
    setCurrentReminder({ id: null, title: '', description: '', date: '' });
    setShowEditPopup(false);
  };

  const handleDeleteReminder = (id) => {
    const updatedReminders = reminders.filter((reminder) => reminder.id !== id);
    setReminders(updatedReminders);
  };

  return (
    <div className={`dashboard-container ${darkMode ? 'dark' : 'light'}`}>
      <aside className="sidebar">
        <nav className="flex-1">
          <ul>
            <li><a href="/notes" className="sidebar-link">Notes</a></li>
            <li><a href="/schedule" className="sidebar-link">Schedule</a></li>
            <li><a href="/garden" className="sidebar-link">Garden</a></li>
            <li><a href="/tasks" className="sidebar-link">Tasks</a></li>
            <li><a href="/reminders" className="current-sidebar-link">Reminders</a></li>
            <li><a href="/timer" className="sidebar-link">Timers</a></li>
            <li><a href="/pomodoro" className="sidebar-link">Pomodoro</a></li>
          </ul>
        </nav>
        <div className="sidebar-footer">
                    <button>{userName}</button>
                </div>
      </aside>
      <div className="main-content">
        <header className="navbar">
          <h1>MindBloom | Reminders</h1>
          <nav>
            <ul>
              <li><a href="/" className="navbar-link">Home</a></li>
              <li><a href="/about" className="navbar-link">About</a></li>
              <li><a href="/contact" className="navbar-link">Contact</a></li>
            </ul>
          </nav>
        </header>
        <div className="content">
          <h2>Welcome to your reminders dashboard!</h2>
          <button className="add-reminder-button" onClick={openAddPopup}>+</button>
          <div className="reminder-grid">
            {reminders.map((reminder) => (
              <div key={reminder.id} className="reminder-card">
                <h3>{reminder.title}</h3>
                <p>{reminder.description}</p>
                <p>{reminder.date}</p>
                <div className="reminder-actions">
                  <button onClick={() => openEditPopup(reminder)}>Edit</button>
                  <button onClick={() => handleDeleteReminder(reminder.id)}>Delete</button>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
      {showAddPopup && (
        <div className="popup-overlay">
          <div className="popup">
            <h2>Add Reminder</h2>
            <div className="popup-content">
              <label>Title:</label>
              <input
                type="text"
                name="title"
                value={currentReminder.title}
                onChange={handlePopupChange}
              />
              <label>Description:</label>
              <textarea
                name="description"
                value={currentReminder.description}
                onChange={handlePopupChange}
              />
              <label>Date:</label>
              <input
                type="date"
                name="date"
                value={currentReminder.date}
                onChange={handlePopupChange}
              />
            </div>
            <div className="popup-buttons">
              <button onClick={handleAddReminder}>Add</button>
              <button onClick={closeAddPopup}>Cancel</button>
            </div>
          </div>
        </div>
      )}
      {showEditPopup && (
        <div className="popup-overlay">
          <div className="popup">
            <h2>Edit Reminder</h2>
            <div className="popup-content">
              <label>Title:</label>
              <input
                type="text"
                name="title"
                value={currentReminder.title}
                onChange={handlePopupChange}
              />
              <label>Description:</label>
              <textarea
                name="description"
                value={currentReminder.description}
                onChange={handlePopupChange}
              />
              <label>Date:</label>
              <input
                type="date"
                name="date"
                value={currentReminder.date}
                onChange={handlePopupChange}
              />
            </div>
            <div className="popup-buttons">
              <button onClick={handleEditReminder}>Save</button>
              <button onClick={closeEditPopup}>Cancel</button>
            </div>
          </div>
        </div>
      )}
    </div>
  );
}

export default Dashboard;
