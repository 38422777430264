import React from 'react';
import { Link } from 'react-router-dom';
import AOS from 'aos';
import 'aos/dist/aos.css';
import { BiLogoInstagram, BiLogoFacebook, BiLogoTiktok, BiLogoDiscordAlt } from 'react-icons/bi';
import './Blog.css';
import '../../App.css';

const OwnYourADHD = () => {
  return (
    <div className='blog-app'>
      <div className="home-nav">
        <img src="logo192.png" alt="MindBloom Logo" />
        <h1>MindBloom</h1>
        <div className='home-index-buttons'>
          <Link className="home-index-links" to="/">About</Link>
          <Link className="home-index-links" to="/pricing">Pricing</Link>
          <Link className="home-index-links" to="/contact">Contact</Link>
        </div>
        <div className="home-auth-buttons">
          <Link className="home-login-link" to="/login">Login</Link>
          <Link className="home-signup-link" to="/signup">Signup</Link>
        </div>
      </div>

      <div className="blog-container">
        <h1 className="blog-title">Own Your ADHD</h1>
        <img src="/blogs/own-adhd.jpg" alt="Own Your ADHD" className="blog-header-image" />
        <div className="blog-content">
          <p>Owning your ADHD means embracing your unique way of processing information and tackling challenges. It’s about understanding that ADHD is a part of who you are and using that understanding to improve your life. In this blog, we’ll explore ways to own your ADHD and use it as a strength.</p>

          <h2>1. Understand Your ADHD</h2>
          <p>Knowledge is power. Learn about ADHD and how it affects you specifically. Understanding your symptoms and triggers will help you develop strategies to manage them effectively.</p>

          <h2>2. Embrace Your Strengths</h2>
          <p>ADHD can come with unique strengths, such as creativity, hyperfocus, and problem-solving skills. Embrace these strengths and find ways to utilize them in your personal and professional life.</p>

          <h2>3. Develop Coping Strategies</h2>
          <p>Develop strategies that work for you, whether it’s using organizational tools, setting reminders, or breaking tasks into smaller steps. Find what helps you stay focused and productive.</p>

          <h2>4. Seek Support</h2>
          <p>Don’t be afraid to ask for help. Support from friends, family, or a therapist can provide valuable insights and encouragement.</p>

          <h2>5. Practice Self-Compassion</h2>
          <p>Be kind to yourself. Understand that ADHD can present challenges, and it’s okay to have setbacks. Practice self-compassion and focus on progress, not perfection.</p>

          <p>Owning your ADHD is a journey. By embracing your condition and finding ways to manage it effectively, you can lead a fulfilling and successful life.</p>
        </div>
      </div>

      <footer className="footer">
        <div className="social-icons">
          <a href="https://instagram.com/mindbloomapp" target="_blank" rel="noopener noreferrer">
            <BiLogoInstagram />
          </a>
          <a href="https://facebook.com/mindbloomapp" target="_blank" rel="noopener noreferrer">
            <BiLogoFacebook />
          </a>
          <a href="https://tiktok.com/@mindbloomapp" target="_blank" rel="noopener noreferrer">
            <BiLogoTiktok />
          </a>
          <a href="https://discord.gg/73cSXYs86w" target="_blank" rel="noopener noreferrer">
            <BiLogoDiscordAlt />
          </a>
        </div>
        <div className="footer-links">
          <Link to="/terms">Terms of Service</Link>
          <Link to="/privacy">Privacy Policy</Link>
          <Link to="/legal">Legal</Link>
        </div>
        <p>&copy; 2024 MindBloom. All rights reserved.</p>
      </footer>
    </div>
  );
};

export default OwnYourADHD;
