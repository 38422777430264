import './Blog.css';
import '../../App.css';
import React from 'react';
import { Link } from 'react-router-dom';
import AOS from 'aos';
import 'aos/dist/aos.css';
import { BiLogoInstagram, BiLogoFacebook, BiLogoTiktok, BiLogoDiscordAlt } from 'react-icons/bi';

const BlogPage = () => {
  return (
    <div className='blog-app'>
      <div className="home-nav">
        <img src="logo192.png" alt="MindBloom Logo" />
        <h1>MindBloom</h1>
        <div className='home-index-buttons'>
          <Link className="home-index-links" to="/">About</Link>
          <Link className="home-index-links" to="/pricing">Pricing</Link>
          <Link className="home-index-links" to="/contact">Contact</Link>
          <Link className="home-index-links" to="/blogs">Blog</Link>

        </div>
        <div className="home-auth-buttons">
          <Link className="home-login-link" to="/login">Login</Link>
          <Link className="home-signup-link" to="/signup">Signup</Link>
        </div>
      </div>

      <div className="blog-container">
        <h1 className="blog-title">How MindBloom Can Help You Achieve Your Goals and Manage ADHD and OCD</h1>
        
        <div className="blog-content">
          <h2>Introduction</h2>
          <p>At MindBloom, our mission is to empower individuals with ADHD, OCD, and other organizational challenges to lead more productive, balanced, and fulfilling lives. Our platform offers a suite of tools designed to help you manage your time, track your goals, and stay organized, all while providing support and encouragement along the way. In this blog, we'll explore how MindBloom can make a positive impact on your daily life and overall well-being.</p>
          
          <h2>1. Goal Tracking</h2>
          <p>Setting and achieving goals can be a challenge, especially for individuals with ADHD and OCD. MindBloom simplifies this process by providing an intuitive goal-tracking feature. You can set SMART (Specific, Measurable, Achievable, Relevant, Time-bound) goals, break them down into smaller tasks, and track your progress. With visual progress indicators and reminders, you'll stay motivated and focused on reaching your milestones.</p>
          
          <h2>2. Daily Scheduling</h2>
          <p>Organizing your day effectively is crucial for productivity. MindBloom's scheduling tool allows you to plan your day in advance, allocate time for specific tasks, and set reminders for important events. By creating a structured routine, you'll find it easier to stay on track and manage your time efficiently.</p>
          
          <h2>3. Note Taking</h2>
          <p>Keeping track of important information, ideas, and tasks is made simple with MindBloom's note-taking feature. You can create, organize, and categorize notes, making it easy to find what you need when you need it. This feature is particularly useful for individuals who struggle with forgetfulness or need a central place to store their thoughts and plans.</p>
          
          <h2>4. Pomodoro Timer</h2>
          <p>The Pomodoro Technique is a time management method that helps improve focus and productivity by breaking work into intervals, traditionally 25 minutes in length, separated by short breaks. MindBloom's built-in Pomodoro timer allows you to customize your work and break intervals to suit your needs, helping you maintain concentration and avoid burnout.</p>
          
          <h2>5. Rewards System</h2>
          <p>To keep you motivated, MindBloom incorporates a rewards system where you earn points for completing tasks and achieving goals. These points can be used to plant virtual seeds in your MindBloom garden, visually representing your growth and progress. This gamified approach makes staying organized fun and engaging.</p>
          
          <h2>6. Community Support</h2>
          <p>MindBloom isn't just a tool; it's a community. Our platform connects you with others who share similar challenges and goals. Through forums, chat rooms, and support groups, you can share experiences, exchange advice, and find encouragement from a supportive network of individuals who understand what you're going through.</p>
          
          <h2>7. Personalized Insights and Tips</h2>
          <p>MindBloom offers personalized insights and tips based on your usage patterns and progress. These tailored recommendations help you optimize your strategies and stay on top of your organizational and productivity goals. Whether it's a new technique to try or a reminder to take a break, MindBloom's insights keep you informed and motivated.</p>
          
          <h2>Conclusion</h2>
          <p>At MindBloom, we believe that everyone has the potential to thrive, regardless of the challenges they face. Our platform is designed to provide the tools, support, and motivation you need to manage ADHD, OCD, and other organizational difficulties. By integrating goal tracking, scheduling, note-taking, the Pomodoro Technique, a rewards system, community support, and personalized insights, MindBloom helps you stay focused, productive, and on the path to achieving your dreams. Join MindBloom today and start your journey towards a more organized and fulfilling life.</p>
        </div>
      </div>
      
      <footer className="footer">
        <div className="social-icons">
          <a href="https://instagram.com/mindbloomapp" target="_blank" rel="noopener noreferrer">
            <BiLogoInstagram />
          </a>
          <a href="https://facebook.com/mindbloomapp" target="_blank" rel="noopener noreferrer">
            <BiLogoFacebook />
          </a>
          <a href="https://tiktok.com/@mindbloomapp" target="_blank" rel="noopener noreferrer">
            <BiLogoTiktok />
          </a>
          <a href="https://discord.gg/73cSXYs86w" target="_blank" rel="noopener noreferrer">
            <BiLogoDiscordAlt />
          </a>
        </div>
        <div className="footer-links">
          <Link to="/terms">Terms of Service</Link>
          <Link to="/privacy">Privacy Policy</Link>
          <Link to="/legal">Legal</Link>
        </div>
        <p>&copy; 2024 MindBloom. All rights reserved.</p>
      </footer>
    </div>
  );
};

export default BlogPage;
