import React, { useState, useEffect } from 'react';
import { account, checkPremiumLabel } from '../lib/appwrite'; // Adjust the path as per your project structure
import './Dashboard.css';

function Dashboard() {
    const [darkMode, setDarkMode] = useState(false);
    const [userName, setUserName] = useState('');

    useEffect(() => {
        const fetchUserData = async () => {
            try {
                const user = await account.get();
                setUserName(user.name);

                const isPremium = await checkPremiumLabel();
                //if (!isPremium) {
                  //  window.location.href = '/pricing';
                //}
            } catch (error) {
                console.error('Error fetching user data:', error);
                window.location.href = '/pricing';
            }
        };

        fetchUserData();
    }, []);

    useEffect(() => {
        // Store userName in localStorage when it changes
        localStorage.setItem('user', JSON.stringify({ name: userName }));
    }, [userName]);

    const toggleDarkMode = () => {
        setDarkMode(!darkMode);
    };

    return (
        <div className={`dashboard-container ${darkMode ? 'dark' : 'light'}`}>
            <aside className="sidebar">
                <nav className="flex-1">
                    <ul>
                        <li><a href="/notes" className="sidebar-link">Notes</a></li>
                        <li><a href="/schedule" className="sidebar-link">Schedule</a></li>
                        <li><a href="/garden" className="sidebar-link">Garden</a></li>
                        <li><a href="/tasks" className="sidebar-link">Tasks</a></li>
                        <li><a href="/reminders" className="sidebar-link">Reminders</a></li>
                        <li><a href="/timer" className="sidebar-link">Timers</a></li>
                        <li><a href="/pomodoro" className="sidebar-link">Pomodoro</a></li>
                    </ul>
                </nav>
                <div className="sidebar-footer">
                    <button>{userName}</button>
                </div>
            </aside>
            <div className="main-content">
                <header className="navbar">
                    <h1>MindBloom | Garden</h1>
                    <nav>
                        <ul>
                            <li><a href="/" className="navbar-link">Home</a></li>
                            <li><a href="/about" className="navbar-link">About</a></li>
                            <li><a href="/contact" className="navbar-link">Contact</a></li>
                        </ul>
                    </nav>
                </header>
                <div className="content">
                    <h2>Welcome to your dashboard!</h2>
                    <p>Here you can manage your tasks, track your progress, and much more.</p>
                </div>
            </div>
        </div>
    );
}

export default Dashboard;