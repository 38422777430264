import React, { useState, useEffect } from 'react';
import { account } from '../lib/appwrite'; // Adjust the path as per your project structure
import './Login.css';
import { FaGooglePlusG } from "react-icons/fa";

const Login = () => {
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [error, setError] = useState('');

    useEffect(() => {
        // Check if a session ID exists in local storage
        const sessionId = localStorage.getItem('sessionId');
        if (sessionId) {
            // Delete the session if it exists
            account.deleteSession('current').then(() => {
                console.log('Session deleted');
                localStorage.removeItem('sessionId');
            }).catch((err) => {
                console.error('Error deleting session:', err);
            });
        }
    }, []);
    const handleGoogleLogin = () => {
        // Trigger OAuth2 login with Google
        account.createOAuth2Session(
            'google', // provider
            'http://localhost:3000/dashboard', // redirect here on success
            'https://mindbloomapp.com/signup' // redirect here on failure
        );
    };
    const handleLogin = async (e) => {
        e.preventDefault();
        setError('');

        try {
            console.log('Attempting login with email:', email);
            console.log('Password length:', password.length);

            // Ensure createEmailSession is correctly used
            const response = await account.createEmailPasswordSession(email, password);
            console.log(response); // Success
            
            // Store session ID in local storage
            localStorage.setItem('sessionId', response.$id);

            // Redirect to dashboard or another page
        } catch (err) {
            console.error('Login error:', err);
            if (err.code === 400) {
                setError('Invalid email or password.');
            } else {
                setError('Login failed. Please check your credentials.');
            }
        }
    };

    return (
        <div className="login-container">
            <div className="login-box">
                <h2>Login</h2>
                {error && <p className="error-message">{error}</p>}
                <form onSubmit={handleLogin}>
                    <label htmlFor="email">Email</label>
                    <input
                        type="email"
                        id="email"
                        value={email}
                        onChange={(e) => setEmail(e.target.value)}
                        required
                    />
                    <label htmlFor="password">Password</label>
                    <input
                        type="password"
                        id="password"
                        value={password}
                        onChange={(e) => setPassword(e.target.value)}
                        required
                    />
                    <button type="submit">Login</button>
                    <button className="google-login" type="button" id="google-login" onClick={handleGoogleLogin}>Login with Google</button>

                </form>
                <p>
                    Don't have an account? <a href="/signup">Sign Up</a>
                </p>
            </div>
        </div>
    );
};

export default Login;