import React, { useState, useEffect } from 'react';
import { Calendar, momentLocalizer } from 'react-big-calendar';
import moment from 'moment';
import 'react-big-calendar/lib/css/react-big-calendar.css';
import './Dashboard.css';
import { Client, Databases, Account, Query } from 'appwrite';

const localizer = momentLocalizer(moment);

const Dashboard = () => {
  const [darkMode, setDarkMode] = useState(false);
  const [events, setEvents] = useState([]);
  const [userName, setUserName] = useState('');
  const [userId, setUserId] = useState('');

  const client = new Client();
  client
    .setEndpoint('https://cloud.appwrite.io/v1') // Your Appwrite endpoint
    .setProject('668c60c700398dc80f00'); // Your project ID
  const databases = new Databases(client);
  const account = new Account(client);

  useEffect(() => {
    const fetchUserData = async () => {
      try {
        const user = await account.get();
        console.log('User data fetched:', user); // Log user data
        setUserName(user.name);
        setUserId(user.$id);
        localStorage.setItem('user', JSON.stringify({ id: user.$id, name: user.name }));

        fetchEvents(user.$id);
      } catch (error) {
        console.error('Error fetching user data:', error.message);
      }
    };

    fetchUserData();
  }, []);

  const fetchEvents = async (userId) => {
    try {
      const response = await databases.listDocuments(
        '6690622800253f723a68', // Your database ID
        '6692d28d00331d94006d', // Your collection ID
        [Query.equal('userId', userId)]
      );
      console.log('Events fetched from Appwrite:', response); // Log fetched events
      const formattedEvents = response.documents.map(doc => ({
        id: doc.$id,
        title: doc.title,
        start: new Date(doc.start),
        end: new Date(doc.end)
      }));
      setEvents(formattedEvents);
    } catch (error) {
      console.error('Error fetching events from Appwrite:', error.message);
    }
  };

  const toggleDarkMode = () => {
    setDarkMode(!darkMode);
  };

  const handleSelectSlot = async ({ start, end }) => {
    console.log('Slot selected:', { start, end }); // Log the selected slot
    const title = window.prompt('New Event Name');
    if (title) {
      try {
        const user = JSON.parse(localStorage.getItem('user'));
        const newEvent = {
          title,
          start: start.toISOString(),
          end: end.toISOString(),
          userId: user.id
        };

        console.log('Creating new event:', newEvent); // Log the event to be created

        const response = await databases.createDocument(
          '6690622800253f723a68', // Your database ID
          '6692d28d00331d94006d', // Your collection ID
          'unique()', // Unique ID for the new document
          newEvent
        );

        console.log('Event created successfully:', response); // Log the response from Appwrite

        setEvents([...events, { ...newEvent, id: response.$id }]);
      } catch (error) {
        console.error('Error creating event:', error.message);
      }
    }
  };

  const handleSelectEvent = async (event) => {
    console.log('Event selected:', event); // Log the selected event
    const action = window.confirm(`Do you want to delete the event '${event.title}'?`);
    if (action) {
      try {
        console.log('Deleting event:', event); // Log the event to be deleted
        await databases.deleteDocument(
          '6690622800253f723a68', // Your database ID
          '6692d28d00331d94006d', // Your collection ID
          event.id
        );
        console.log('Event deleted successfully'); // Log the successful deletion
        setEvents(events.filter((e) => e.id !== event.id));
      } catch (error) {
        console.error('Error deleting event:', error.message);
      }
    }
  };

  return (
    <div className={`dashboard-container ${darkMode ? 'dark' : 'light'}`}>
      <aside className="sidebar">
        <nav className="flex-1">
          <ul>
            <li><a href="/notes" className="sidebar-link">Notes</a></li>
            <li><a href="/schedule" className="current-sidebar-link">Schedule</a></li>
            <li><a href="/garden" className="sidebar-link">Garden</a></li>
            <li><a href="/tasks" className="sidebar-link">Tasks</a></li>
            <li><a href="/reminders" className="sidebar-link">Reminders</a></li>
            <li><a href="/timer" className="sidebar-link">Timers</a></li>
            <li><a href="/pomodoro" className="sidebar-link">Pomodoro</a></li>
          </ul>
        </nav>
        <div className="sidebar-footer">
          <button>{userName}</button>
        </div>
      </aside>
      <div className="main-content">
        <header className="navbar">
          <h1>MindBloom | Schedule</h1>
          <nav>
            <ul>
              <li><a href="/" className="navbar-link">Home</a></li>
              <li><a href="/about" className="navbar-link">About</a></li>
              <li><a href="/contact" className="navbar-link">Contact</a></li>
            </ul>
          </nav>
        </header>
        <div className="content">
          <h2>Welcome to your scheduling dashboard!</h2>
          <p>Here you can manage your schedules and appointments.</p>
          <div className="view-options">
            <button onClick={() => toggleDarkMode()}>
              {darkMode ? 'Switch to Light Mode' : 'Switch to Dark Mode'}
            </button>
          </div>
          <Calendar
            localizer={localizer}
            events={events}
            startAccessor="start"
            endAccessor="end"
            style={{ height: 500, margin: '50px' }}
            selectable
            onSelectSlot={handleSelectSlot}
            onSelectEvent={handleSelectEvent}
          />
        </div>
      </div>
    </div>
  );
};

export default Dashboard;