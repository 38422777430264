import './Blog.css';
import '../../App.css';
import React from 'react';
import { Link } from 'react-router-dom';
import AOS from 'aos';
import 'aos/dist/aos.css';
import { BiLogoInstagram, BiLogoFacebook, BiLogoTiktok, BiLogoDiscordAlt } from 'react-icons/bi';

const BlogPage = () => {
  return (
    <div className='blog-app'>
      <div className="home-nav">
      <img src="logo192.png" alt="MindBloom Logo" />
      <h1>MindBloom</h1>
        <div className='home-index-buttons'>
          <Link className="home-index-links" to="/">About</Link>
          <Link className="home-index-links" to="/pricing">Pricing</Link>
          <Link className="home-index-links" to="/contact">Contact</Link>
          <Link className="home-index-links" to="/blogs">Blog</Link>
        </div>
        <div className="home-auth-buttons">
          <Link className="home-login-link" to="/login">Login</Link>
          <Link className="home-signup-link" to="/signup">Signup</Link>
        </div>
      </div>

      <div className="blog-container">
        <h1 className="blog-title">Understanding ADHD: A Guide to Self-Diagnosis and Seeking Professional Help</h1>
        
        <div className="blog-content">
          <h2>Introduction</h2>
          <p>At MindBloom, we understand the challenges of living with ADHD and the importance of recognizing the signs early on. Whether you're struggling to focus, feeling overwhelmed by daily tasks, or constantly battling forgetfulness, you may be wondering if ADHD is the underlying cause. This guide will help you understand ADHD better, explore self-diagnosis, and emphasize the importance of seeking professional help.</p>
          
          <h2>What is ADHD?</h2>
          <p>Attention Deficit Hyperactivity Disorder (ADHD) is a neurodevelopmental disorder that affects both children and adults. It is characterized by symptoms of inattention, hyperactivity, and impulsivity. While it's normal to experience some of these symptoms occasionally, individuals with ADHD experience them at a level that interferes with their daily life and functioning.</p>
          
          <h2>Signs and Symptoms of ADHD</h2>
          <p>ADHD symptoms are typically divided into two categories: inattention and hyperactivity-impulsivity. Here are some common signs to watch for:</p>
          
          <h3>Inattention:</h3>
          <ul>
            <li>Difficulty sustaining attention in tasks or play activities</li>
            <li>Frequent careless mistakes in schoolwork or other activities</li>
            <li>Often seems not to listen when spoken to directly</li>
            <li>Struggles to follow through on instructions and fails to finish tasks</li>
            <li>Difficulty organizing tasks and activities</li>
            <li>Avoids tasks that require sustained mental effort</li>
            <li>Often loses items necessary for tasks and activities</li>
            <li>Easily distracted by extraneous stimuli</li>
            <li>Forgetful in daily activities</li>
          </ul>
          
          <h3>Hyperactivity-Impulsivity:</h3>
          <ul>
            <li>Fidgeting with or tapping hands or feet, or squirming in seat</li>
            <li>Inability to stay seated in situations where expected</li>
            <li>Running or climbing in inappropriate situations</li>
            <li>Unable to play or engage in activities quietly</li>
            <li>Often "on the go" or acting as if "driven by a motor"</li>
            <li>Excessive talking</li>
            <li>Blurting out answers before questions have been completed</li>
            <li>Difficulty waiting for one's turn</li>
            <li>Interrupting or intruding on others' conversations or games</li>
          </ul>
          
          <h2>Self-Diagnosis: A Starting Point</h2>
          <p>While self-diagnosis can be a helpful starting point, it's important to remember that only a qualified healthcare professional can provide a definitive diagnosis. Here are some steps to help you reflect on your symptoms:</p>
          
          <h3>1. Keep a Journal:</h3>
          <p>Document your daily activities and note instances where you experience symptoms of inattention or hyperactivity-impulsivity. Pay attention to patterns and triggers.</p>
          
          <h3>2. Take Online ADHD Quizzes:</h3>
          <p>There are several reputable online quizzes and self-assessment tools that can help you gauge whether you might have ADHD. These tools are not diagnostic but can provide insight into your symptoms.</p>
          
          <h3>3. Reflect on Your History:</h3>
          <p>Consider whether you’ve experienced these symptoms since childhood. ADHD is often present from an early age, even if it wasn’t diagnosed at the time.</p>
          
          <h3>4. Talk to Trusted Individuals:</h3>
          <p>Discuss your symptoms with family members, friends, or colleagues who know you well. They might provide valuable insights and observations.</p>
          
          <h2>Seeking Professional Help</h2>
          <p>If your self-assessment points to ADHD, the next step is to seek professional help. Here's why it matters:</p>
          
          <h3>1. Accurate Diagnosis:</h3>
          <p>A healthcare professional, such as a psychiatrist, psychologist, or neurologist, can conduct a thorough evaluation to provide an accurate diagnosis. This process may include interviews, questionnaires, and possibly other assessments.</p>
          
          <h3>2. Personalized Treatment:</h3>
          <p>Once diagnosed, a professional can help you develop a personalized treatment plan. This may include medication, therapy, lifestyle changes, and strategies for managing symptoms.</p>
          
          <h3>3. Support and Resources:</h3>
          <p>A diagnosis can open the door to a wealth of resources and support networks. At MindBloom, we offer tools and strategies specifically designed to help individuals with ADHD stay organized and focused.</p>
          
          <h2>Conclusion</h2>
          <p>Recognizing the signs of ADHD and taking steps toward understanding your symptoms is a significant first step in managing the condition. At MindBloom, we're here to support you on this journey. Remember, while self-diagnosis can be enlightening, seeking professional help is crucial for an accurate diagnosis and effective treatment. If you suspect you have ADHD, don't hesitate to reach out to a healthcare professional. Together, we can help you thrive and achieve your goals.</p>
          <p>If you’re ready to take the next step, explore our resources at MindBloom and connect with a professional today. Our platform is designed to help you stay organized, focused, and in control of your life. Start your journey with MindBloom and bloom to your full potential!</p>
        </div>
      </div>
      
      <footer className="footer">
        <div className="social-icons">
          <a href="https://instagram.com/mindbloomapp" target="_blank" rel="noopener noreferrer">
            <BiLogoInstagram />
          </a>
          <a href="https://facebook.com/mindbloomapp" target="_blank" rel="noopener noreferrer">
            <BiLogoFacebook />
          </a>
          <a href="https://tiktok.com/@mindbloomapp" target="_blank" rel="noopener noreferrer">
            <BiLogoTiktok />
          </a>
          <a href="https://discord.gg/73cSXYs86w" target="_blank" rel="noopener noreferrer">
            <BiLogoDiscordAlt />
          </a>
        </div>
        <div className="footer-links">
          <Link to="/terms">Terms of Service</Link>
          <Link to="/privacy">Privacy Policy</Link>
          <Link to="/legal">Legal</Link>
        </div>
        <p>&copy; 2024 MindBloom. All rights reserved.</p>
      </footer>
    </div>
  );
};

export default BlogPage;