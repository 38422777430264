import React from 'react';
import { Link } from 'react-router-dom';
import AOS from 'aos';
import 'aos/dist/aos.css';
import { BiLogoInstagram, BiLogoFacebook, BiLogoTiktok, BiLogoDiscordAlt } from 'react-icons/bi';
import './Blog.css';
import '../../App.css';

const WhatIsDecisionFatigue = () => {
  return (
    <div className='blog-app'>
      <div className="home-nav">
        <img src="logo192.png" alt="MindBloom Logo" />
        <h1>MindBloom</h1>
        <div className='home-index-buttons'>
          <Link className="home-index-links" to="/">About</Link>
          <Link className="home-index-links" to="/pricing">Pricing</Link>
          <Link className="home-index-links" to="/contact">Contact</Link>
        </div>
        <div className="home-auth-buttons">
          <Link className="home-login-link" to="/login">Login</Link>
          <Link className="home-signup-link" to="/signup">Signup</Link>
        </div>
      </div>

      <div className="blog-container">
        <h1 className="blog-title">What is Decision Fatigue?</h1>
        <img src="/blogs/decision-fatigue.jpg" alt="Decision Fatigue" className="blog-header-image" />
        <div className="blog-content">
          <p>Decision fatigue is the deterioration of our decision-making abilities after making numerous decisions. It can lead to poor choices and decreased willpower. In this blog, we'll delve into what decision fatigue is, its effects, and strategies to mitigate it.</p>

          <h2>1. Understanding Decision Fatigue</h2>
          <p>Decision fatigue occurs when we experience mental exhaustion from making too many decisions. This can reduce our ability to make thoughtful and effective choices.</p>

          <h2>2. Effects of Decision Fatigue</h2>
          <p>Decision fatigue can lead to procrastination, decision avoidance, and poorer quality decisions. It can impact both personal and professional aspects of life.</p>

          <h2>3. Recognizing Symptoms</h2>
          <p>Common symptoms include feeling overwhelmed by choices, experiencing decision paralysis, and making impulsive or irrational decisions.</p>

          <h2>4. Strategies to Combat Decision Fatigue</h2>
          <p>Strategies include simplifying choices, setting decision-making limits, and creating routines to reduce the number of decisions you need to make.</p>

          <h2>5. Seeking Professional Help</h2>
          <p>In some cases, professional help may be needed to address underlying issues contributing to decision fatigue. Therapy and coaching can provide valuable support.</p>

          <p>By understanding and managing decision fatigue, you can improve your decision-making abilities and overall well-being. MindBloom offers tools to help you streamline decisions and enhance productivity.</p>
        </div>
      </div>

      <footer className="footer">
        <div className="social-icons">
          <a href="https://instagram.com/mindbloomapp" target="_blank" rel="noopener noreferrer">
            <BiLogoInstagram />
          </a>
          <a href="https://facebook.com/mindbloomapp" target="_blank" rel="noopener noreferrer">
            <BiLogoFacebook />
          </a>
          <a href="https://tiktok.com/@mindbloomapp" target="_blank" rel="noopener noreferrer">
            <BiLogoTiktok />
          </a>
          <a href="https://discord.gg/73cSXYs86w" target="_blank" rel="noopener noreferrer">
            <BiLogoDiscordAlt />
          </a>
        </div>
        <div className="footer-links">
          <Link to="/terms">Terms of Service</Link>
          <Link to="/privacy">Privacy Policy</Link>
          <Link to="/legal">Legal</Link>
        </div>
        <p>&copy; 2024 MindBloom. All rights reserved.</p>
      </footer>
    </div>
  );
};

export default WhatIsDecisionFatigue;
