import React, { useEffect } from 'react';
import { Link } from 'react-router-dom';
import AOS from 'aos';
import 'aos/dist/aos.css';
import { BiLogoInstagram, BiLogoFacebook, BiLogoTiktok, BiLogoDiscordAlt } from 'react-icons/bi';
import '../App.css';

function TermsOfServicePage() {
  useEffect(() => {
    AOS.init({
      duration: 1000, // animation duration in ms
      once: true, // whether animation should happen only once - while scrolling down
    });
  }, []);

  return (
    <div className="home-App">
      <div className="home-nav">
        <img src="logo192.png" alt="MindBloom Logo" />
        <h1>MindBloom</h1>
        <div className='home-index-buttons'>
          <Link className="home-index-links" to="/">About</Link>
          <Link className="home-index-links" to="/pricing">Pricing</Link>
          <Link className="home-index-links" to="/contact">Contact</Link>
          <Link className="home-index-links" to="/blog">Blog</Link>
        </div>
        <div className="home-auth-buttons">
          <Link className="home-login-link" to="/login">
            Login
          </Link>
          <Link className="home-signup-link" to="/signup">
            Signup
          </Link>
        </div>
      </div>
      <div className="terms-container">
        <header className="terms-header">
          <h1 data-aos="fade-up">Terms of Service</h1>
          <p data-aos="fade-up">Understand the terms under which you can use MindBloom's services.</p>
        </header>
        <div className="terms-content" data-aos="fade-up">
          <section>
            <h2>Introduction</h2>
            <p>
              Welcome to MindBloom! These Terms of Service outline the rules and regulations for the use of our website and services. By accessing this website, you accept these terms and conditions in full.
            </p>
          </section>
          <section>
            <h2>Use of Our Services</h2>
            <p>
              MindBloom is dedicated to helping individuals with ADHD, OCD, and other focus-related challenges stay organized and productive. By using our services, you agree to use them responsibly and in compliance with all applicable laws.
            </p>
          </section>
          <section>
            <h2>User Accounts</h2>
            <p>
              To access some of MindBloom’s features, you may need to create an account. You are responsible for maintaining the confidentiality of your account information and for all activities that occur under your account.
            </p>
          </section>
          <section>
            <h2>Intellectual Property</h2>
            <p>
              All content, trademarks, and data on this site, including but not limited to software, databases, text, graphics, icons, and hyperlinks, are the property of or licensed to MindBloom and are protected by law.
            </p>
          </section>
          <section>
            <h2>Termination</h2>
            <p>
              We reserve the right to suspend or terminate your access to MindBloom’s services at our discretion, without notice, if we believe you have violated these terms or are otherwise engaged in illegal or harmful conduct.
            </p>
          </section>
          <section>
            <h2>Limitation of Liability</h2>
            <p>
              MindBloom will not be liable for any direct, indirect, incidental, special, or consequential damages resulting from the use or the inability to use our services, or for the cost of procurement of substitute services.
            </p>
          </section>
          <section>
            <h2>Changes to These Terms</h2>
            <p>
              MindBloom reserves the right to modify these Terms of Service at any time. Changes and clarifications will take effect immediately upon their posting on the website. It is your responsibility to check this page periodically for updates.
            </p>
          </section>
          <section>
            <h2>Contact Us</h2>
            <p>
              If you have any questions about these Terms of Service, please contact us at support@mindbloom.com.
            </p>
          </section>
        </div>
      </div>
      <footer className="footer">
        <div className="social-icons">
          <a href="https://instagram.com/mindbloomapp" target="_blank" rel="noopener noreferrer">
            <BiLogoInstagram />
          </a>
          <a href="https://facebook.com/mindbloomapp" target="_blank" rel="noopener noreferrer">
            <BiLogoFacebook />
          </a>
          <a href="https://tiktok.com/@mindbloomapp" target="_blank" rel="noopener noreferrer">
            <BiLogoTiktok />
          </a>
          <a href="https://discord.gg/73cSXYs86w" target="_blank" rel="noopener noreferrer">
            <BiLogoDiscordAlt />
          </a>
        </div>
        <div className="footer-links">
          <Link to="/terms">Terms of Service</Link>
          <Link to="/privacy">Privacy Policy</Link>
          <Link to="/legal">Legal</Link>
        </div>
        <p>&copy; 2024 MindBloom. All rights reserved.</p>
      </footer>
    </div>
  );
}

export default TermsOfServicePage;
